import './css/main.css'
import { Link, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faCircleXmark, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from 'react';
import Sidebar from '../Nav/sidebar';
import NewPage from './NewPage'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import Country from './country'
import { useDispatch, useSelector } from 'react-redux';
import { checkLogin } from '../store';
import { SyncLoader } from "react-spinners";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

function Cslog() {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());
    const [csData, setCsData] = useState([])
    const [csList, setCsList] = useState([])
    const [page, setPage] = useState(1)
    const [spinner, setSpinner] = useState(false)

    let dark = useSelector((state) => { return state.Darkmode.value });
    let SelectType = useSelector((state) => { return state.SelectType.value });

    const darkname = dark ? 'dark' : "";

    let dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            if (Date.now() > obj.expire) {
                localStorage.removeItem('token');
                dispatch(checkLogin(false));
                navigate('/')
            }
            else {
                dispatch(checkLogin(true));
            }
        }
        else {
            dispatch(checkLogin(false));
            navigate('/')
        }
    }, [])

    useEffect(() => {
        if (SelectType != 0 && SelectType != 1) navigate(`/dashboard`)
    }, [SelectType])

    useEffect(() => {
        CSData()
    }, [])

    const CSData = async () => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get('http://211.253.38.162:10220/api/v1/admin/manager/logs', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let tmp = response.data.data
                console.log(tmp)
                if (tmp) {
                    setCsData(tmp.admin_logs)
                }
            }).catch(error => {
                console.error(error);
            });
        }
    }

    useEffect(() => {
        onSpinner()
        setPage(1)
        let arr = []
        if (csData) {
            for (let i = 0; i < csData.length; i++) {
                if (Math.floor(changeTime(selectedDate) / 1000) * 1000 <= new Date(csData[i].start_date_time).getTime() && new Date(csData[i].end_date_time).getTime() <= Math.floor(changeTime(selectedDate1) / 1000) * 1000 + 86399999) {
                    arr.push(csData[i])
                }
            }
            setCsList(arr)
        }
    }, [csData, selectedDate, selectedDate1])

    const onSpinner = () => {
        setSpinner(true)
        setTimeout(() => {
            setSpinner(false)
        }, 500)
    }

    const excelData = () => {
        let arr = []
        if (csData) {
            for (let i = 0; i < csData.length; i++) {
                let t = csData[i]

                let tmpdate = t.date_time
                let formattedDate = `${tmpdate[0]}.${padZero(tmpdate[1])}.${padZero(tmpdate[2])} ${padZero(tmpdate[3])}:${padZero(tmpdate[4])}:${tmpdate[5] ? padZero(tmpdate[5]) : "00"}`;

                arr.push({
                    "Time": formattedDate,
                    "Employee": t.manager,
                    "ActivityLog": t.log,
                    "RFREVID": t.target_uid,
                })
            }
        }

        Excel(arr)
    }

    let slice_data = csList.slice((page - 1) * 20, (page) * 20)

    return (
        <div className='main'>
            <Sidebar page={13} />
            <div className={`main-box ${darkname}`}>
                <div className={`withdraw-1 ${darkname}`}>
                    <div style={{ width: "85px", fontWeight: "bold" }}>CS Activity</div>
                    <div className={`modal-3-1-left ${darkname}`}>
                        <div className="modal-3-1-left-1 flex">
                            <DatePicker
                                dateFormat='yyyy-MM-dd' // 날짜 형태
                                shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                                minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                                maxDate={selectedDate1} // maxDate 이후 날짜 선택 불가
                                selected={selectedDate}
                                onChange={(date) => setSelectedDate(date)}
                            />
                            <div>~</div>
                            <DatePicker
                                dateFormat='yyyy-MM-dd' // 날짜 형태
                                shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                                minDate={selectedDate} // minDate 이전 날짜 선택 불가
                                maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                                selected={selectedDate1}
                                onChange={(date) => setSelectedDate1(date)}
                            />
                        </div>
                    </div>
                </div>
                <div className='cslog-1'>
                    <div className={`tradingType-1-2 ${darkname}`} onClick={() => excelData()}>Excel download</div>
                </div>
                <div className='withdraw-3'>
                    <div className={`table-head ${darkname}`}>
                        <div>Time</div>
                        <div>Employee</div>
                        <div>Activity log</div>
                        <div>RF-Rev ID</div>
                    </div>
                    <div className='table-body' style={{ minHeight: "600px" }}>
                        {
                            spinner
                                ?
                                <div className='spinner-modal'>
                                    <SyncLoader color="#F5AC1D" />
                                </div>
                                :
                                slice_data && slice_data.length === 0
                                    ?
                                    <div className='data-nofound'>
                                        <img src='/img/img_nofound.png'></img>
                                        <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                        <p>NO RECORDS FOUND.</p>
                                    </div>
                                    :
                                    <>
                                        {
                                            slice_data.map(function (a, i) {
                                                return (
                                                    <WithdrawItem key={i} item={a} darkname={darkname} />
                                                )
                                            })
                                        }
                                        <NewPage initialData={csList} page={page} setPage={setPage} maxFirstPage={20} />
                                    </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

function WithdrawItem({ item, darkname }) {
    const [clickId, setClickId] = useState(false)
    let tmpdate = item.start_date_time.split("T")
    let tmpdate1 = tmpdate[1].split(".000Z")
    let formattedDate = `${tmpdate[0]} ${tmpdate1[0]}`;

    return (
        <>
            <div className={`table-body-tr ${darkname}`}>
                <div className='td'>{formattedDate}</div>
                <div className='td'>{item.manager}</div>
                <div className='td'>{item.action}</div>
                <div className='onclick td' onClick={() => setClickId(true)}>{item.target_uid}</div>
            </div>
            {
                clickId
                    ?
                    <ModalId item={item} setClickId={setClickId} darkname={darkname} />
                    :
                    ""
            }
        </>
    )
}

function ModalId({ item, setClickId, darkname }) {
    let SelectType = useSelector((state) => { return state.SelectType.value });

    const [detailData, setDetailData] = useState([])
    const [totalTrade, settotalTrade] = useState([0, 0])
    const [signdate, setSigndate] = useState("")
    const [lastsigndate, setLastSigndate] = useState("")
    const [email, setEmail] = useState("")
    const [mobile, setMobile] = useState("")
    const [phone, setPhone] = useState("")

    const [bnspotMaster, setBnSpotMaster] = useState("0")
    const [bnfuturesMaster, setBnFuturesMaster] = useState("0")
    const [bnspotSelf, setBnSpotSelf] = useState("0")
    const [bnfuturesSelf, setBnFuturesSelf] = useState("0")
    const [okxspotMaster, setOkxSpotMaster] = useState("0")
    const [okxfuturesMaster, setOkxFuturesMaster] = useState("0")
    const [okxspotSelf, setOkxSpotSelf] = useState("0")
    const [okxfuturesSelf, setOkxFuturesSelf] = useState("0")
    const [byspotMaster, setBySpotMaster] = useState("0")
    const [byfuturesMaster, setByFuturesMaster] = useState("0")
    const [byspotSelf, setBySpotSelf] = useState("0")
    const [byfuturesSelf, setByFuturesSelf] = useState("0")

    const [selectStatus, setSelectStatus] = useState(false)
    const [selectStatusValue, setSelectStatusValue] = useState("AGENT")

    const [selectStatus1, setSelectStatus1] = useState(false)
    const [selectStatusValue1, setSelectStatusValue1] = useState(item.level)

    const onChangeBnSpotMaster = (e) => {
        const { value } = e.target;
        let onlyNumber = value.replace(/[^0-9]/g, '');
        if (Number(onlyNumber > 100)) setBnSpotMaster("100");
        else setBnSpotMaster(onlyNumber);
    }
    const onChangeBnFuturesMaster = (e) => {
        const { value } = e.target;
        let onlyNumber = value.replace(/[^0-9]/g, '');
        if (Number(onlyNumber > 100)) setBnFuturesMaster("100");
        else setBnFuturesMaster(onlyNumber);
    }
    const onChangeBnSpotSelf = (e) => {
        const { value } = e.target;
        let onlyNumber = value.replace(/[^0-9]/g, '');
        if (Number(onlyNumber > 100)) setBnSpotSelf("100");
        else setBnSpotSelf(onlyNumber);
    }
    const onChangeBnFuturesSelf = (e) => {
        const { value } = e.target;
        let onlyNumber = value.replace(/[^0-9]/g, '');
        if (Number(onlyNumber > 100)) setBnFuturesSelf("100");
        else setBnFuturesSelf(onlyNumber);
    }
    const onChangeOkxSpotMaster = (e) => {
        const { value } = e.target;
        let onlyNumber = value.replace(/[^0-9]/g, '');
        if (Number(onlyNumber > 100)) setOkxSpotMaster("100");
        else setOkxSpotMaster(onlyNumber);
    }
    const onChangeOkxFuturesMaster = (e) => {
        const { value } = e.target;
        let onlyNumber = value.replace(/[^0-9]/g, '');
        if (Number(onlyNumber > 100)) setOkxFuturesMaster("100");
        else setOkxFuturesMaster(onlyNumber);
    }
    const onChangeOkxSpotSelf = (e) => {
        const { value } = e.target;
        let onlyNumber = value.replace(/[^0-9]/g, '');
        if (Number(onlyNumber > 100)) setOkxSpotSelf("100");
        else setOkxSpotSelf(onlyNumber);
    }
    const onChangeOkxFuturesSelf = (e) => {
        const { value } = e.target;
        let onlyNumber = value.replace(/[^0-9]/g, '');
        if (Number(onlyNumber > 100)) setOkxFuturesSelf("100");
        else setOkxFuturesSelf(onlyNumber);
    }
    const onChangeBySpotMaster = (e) => {
        const { value } = e.target;
        let onlyNumber = value.replace(/[^0-9]/g, '');
        if (Number(onlyNumber > 100)) setBySpotMaster("100");
        else setBySpotMaster(onlyNumber);
    }
    const onChangeByFuturesMaster = (e) => {
        const { value } = e.target;
        let onlyNumber = value.replace(/[^0-9]/g, '');
        if (Number(onlyNumber > 100)) setByFuturesMaster("100");
        else setByFuturesMaster(onlyNumber);
    }
    const onChangeBySpotSelf = (e) => {
        const { value } = e.target;
        let onlyNumber = value.replace(/[^0-9]/g, '');
        if (Number(onlyNumber > 100)) setBySpotSelf("100");
        else setBySpotSelf(onlyNumber);
    }
    const onChangeByFuturesSelf = (e) => {
        const { value } = e.target;
        let onlyNumber = value.replace(/[^0-9]/g, '');
        if (Number(onlyNumber > 100)) setByFuturesSelf("100");
        else setByFuturesSelf(onlyNumber);
    }

    // 유효성검사
    const [checkemail, setCheckEmail] = useState(false);
    const [checkmobile, setCheckMobile] = useState(false)

    const emailRegEx = /^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/i;
    const regex5 = /^[1-9][0-9]{8,10}$/;

    useEffect(() => {
        emailCheck(email)
        mobileCheck(mobile)
    }, [])

    const onChangeEmail = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^\\!-z]/gi, "")
        setEmail(onlyNumber);
        emailCheck(onlyNumber)
    }

    const onChangeMobile = (e) => {
        const { value } = e.target;
        let onlyNumber = value.replace(/[^0-9]/g, '');
        if (onlyNumber.length > 0 && onlyNumber.charAt(0) == '0') {
            onlyNumber = onlyNumber.substring(1);
        }
        setMobile(onlyNumber);
        mobileCheck(onlyNumber);
    }

    const onChangePhone = (e) => {
        setPhone(e.target.value);
    }

    const emailCheck = (username) => {
        if (emailRegEx.test(username)) setCheckEmail(true)
        else setCheckEmail(false);
    }

    const mobileCheck = (username) => {
        if (regex5.test(username)) setCheckMobile(true)
        else setCheckMobile(false);
    }

    useEffect(() => {
        MemberDetailData()
    }, [])

    const MemberDetailData = async () => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get('http://211.253.38.162:10220/api/v1/member/detail', {
                params: {
                    uid: item.target_uid
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let tmp = response.data.data

                if (tmp) {
                    if (tmp?.regist_date) {
                        let tmpdate = tmp.regist_date.split("T")
                        let tmpdate1 = tmpdate[1].split(".000Z")
                        let formattedDate = `${tmpdate[0]} ${tmpdate1[0]}`
                        setSigndate(formattedDate)
                    }

                    if (tmp?.last_access_date) {
                        let last_tmpdate = tmp.last_access_date.split("T")
                        let last_tmpdate1 = last_tmpdate[1].split(".000Z")
                        let last_formattedDate = `${last_tmpdate[0]} ${last_tmpdate1[0]}`
                        setLastSigndate(last_formattedDate)
                    }

                    setDetailData(tmp)
                    settotalTrade([tmp.total_spot, tmp.total_futures])
                    setSelectStatusValue(tmp?.level ?? "AGENT")
                    setEmail(tmp?.emai ?? "")
                    setMobile(tmp?.mobile ?? "")
                    setPhone(tmp?.country_code ?? "")

                    setBnSpotMaster(tmp.bn_master_rebate_spot_percent * 100)
                    setBnFuturesMaster(tmp.bn_master_rebate_futures_percent * 100)
                    setBnSpotSelf(tmp.bn_self_rebate_spot_percent * 100)
                    setBnFuturesSelf(tmp.bn_self_rebate_futures_percent * 100)
                    setOkxSpotMaster(tmp.okx_master_rebate_spot_percent * 100)
                    setOkxFuturesMaster(tmp.okx_master_rebate_swap_percent * 100)
                    setOkxSpotSelf(tmp.okx_self_rebate_spot_percent * 100)
                    setOkxFuturesSelf(tmp.okx_self_rebate_swap_percent * 100)

                    setBySpotMaster(tmp.bybit_master_rebate_spot_percent * 100)
                    setByFuturesMaster(tmp.bybit_master_rebate_futures_percent * 100)
                    setBySpotSelf(tmp.bybit_self_rebate_spot_percent * 100)
                    setByFuturesSelf(tmp.bybit_self_rebate_futures_percent * 100)
                }
            }).catch(error => {
                console.error(error);
            });
        }
    }

    const onChangeMasterData = async (e) => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.post(`http://211.253.38.162:10220/api/v1/update/user`, {
                id: item.uid,
                level: e,
                okx_master_rebate_spot_percent: Number(okxspotMaster),
                okx_master_rebate_swap_percent: Number(okxfuturesMaster),
                okx_self_rebate_spot_percent: Number(okxspotSelf),
                okx_self_rebate_swap_percent: Number(okxfuturesSelf),
                bn_master_rebate_spot_percent: Number(bnspotMaster),
                bn_master_rebate_futures_percent: Number(bnfuturesMaster),
                bn_self_rebate_spot_percent: Number(bnspotSelf),
                bn_self_rebate_futures_percent: Number(bnfuturesSelf),
                bybit_referral_spot_percent: Number(byspotMaster),
                bybit_referral_futures_percent: Number(byfuturesMaster),
                bybit_selferral_spot_percent: Number(byspotSelf),
                bybit_selferral_futures_percent: Number(byfuturesSelf),
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                // setReload(e => !e)
            }).catch(error => {
                console.error(error);
            });
        }
    }

    const onSubmiteEmail = async () => {
        if (checkemail) {
            if (window.confirm("Are you sure you want to change email?") === true) {
                const objString = localStorage.getItem('token');
                if (objString != null) {
                    const obj = JSON.parse(objString);
                    let token = obj.value;

                    await axios.get('http://211.253.38.162:10220/api/v1/admin/member/email', {
                        params: {
                            uid: item.uid,
                            email: email
                        }
                    }, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }).then((response) => {
                        // setReload(e => !e)
                    }).catch(error => {
                        console.error(error);
                    });
                }
            }
        }
    }

    const onSubmiteMobile = async () => {
        if (checkmobile) {
            if (window.confirm("Are you sure you want to change mobile?") === true) {
                const objString = localStorage.getItem('token');
                if (objString != null) {
                    const obj = JSON.parse(objString);
                    let token = obj.value;

                    await axios.get('http://211.253.38.162:10220/api/v1/admin/member/mobile', {
                        params: {
                            uid: item.uid,
                            country_code: phone,
                            mobile: mobile
                        }
                    }, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }).then((response) => {
                        // setReload(e => !e)
                    }).catch(error => {
                        console.error(error);
                    });
                }
            }
        }
    }

    const onResetOTP = async () => {
        if (window.confirm("Are you sure you want to reset otp?") === true) {
            const objString = localStorage.getItem('token');
            if (objString != null) {
                const obj = JSON.parse(objString);
                let token = obj.value;

                await axios.get('http://211.253.38.162:10220/api/v1/admin/member/otp', {
                    params: {
                        uid: item.uid
                    }
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }).then((response) => {
                    // setReload(e => !e)
                }).catch(error => {
                    console.error(error);
                });
            }
        }
    }

    const onResetPassword = async () => {
        if (window.confirm("Are you sure you want to reset password?") === true) {
            const objString = localStorage.getItem('token');
            if (objString != null) {
                const obj = JSON.parse(objString);
                let token = obj.value;

                await axios.get(`http://211.253.38.162:10220/api/v1/member/reset/password`, {
                    params: {
                        id: item.uid
                    },
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }).then((response) => {
                    // setReload(e => !e)
                }).catch(error => {
                    console.error(error);
                });
            }
        }
    }

    const onConfirm = () => {
        if (window.confirm("Are you sure you want to reset data?") === true) {
            onChangeMasterData(selectStatusValue)
            setClickId(false)
        }
    }

    const handleEsc = (event) => {
        if (event.key === 'Escape') {
            setClickId(false);
        }
    };

    document.addEventListener('keydown', handleEsc);

    return (
        <div className='modal'>
            <div className={`modal-box`}>
                <div className={`modal-2 ${darkname}`}>
                    <div className='modal-1-tit-1'>
                        <div>Detail</div>
                        <div className={`modal-1-tit-box ${darkname}`}>RF-Rev ID : {detailData.uid}</div>
                        <div className={`modal-1-tit-box ${darkname}`}>Signup date : {signdate}</div>
                        <div className={`modal-1-tit-box ${darkname}`}>Last login : {lastsigndate}</div>
                    </div>
                    <div className='modal-2-1'>
                        <div className='modal-2-1-left'>
                            <div className='modal-2-1-left-box'>
                                {
                                    SelectType != 2
                                        ?
                                        <div className='modal-2-1-left-2'>
                                            <div className={`modal-2-1-right-box ${darkname}`}>
                                                <div>
                                                    <span style={{ paddingRight: "5px", borderRight: "1px solid #dbdbdb" }}>Email</span>
                                                    <input value={email} onChange={onChangeEmail} placeholder='Email' className='member-input' />
                                                </div>
                                                <div className='modify' onClick={() => onSubmiteEmail()}>modify</div>
                                            </div>
                                            <div className={`modal-2-1-right-box ${darkname}`}>
                                                <div><span style={{ paddingRight: "5px", borderRight: "1px solid #dbdbdb" }}>Mobile</span>
                                                    <input type="search" className='member-country-input' list="list" autoComplete="on" value={phone} onChange={onChangePhone} />
                                                    <datalist id="list">
                                                        {
                                                            Country.map(function (a, i) {
                                                                return (
                                                                    <option value={a.value}>{a.name} ({a.value})</option>
                                                                )
                                                            })
                                                        }
                                                    </datalist>
                                                    <input value={mobile} onChange={onChangeMobile} placeholder='Mobile' className='member-input' />
                                                </div>
                                                <div className='modify' onClick={() => onSubmiteMobile()}>modify</div>
                                            </div>
                                        </div>
                                        :
                                        ""
                                }
                                <div className='modal-2-1-left-box-1'>
                                    <div className={`modal-2-1-left-3 ${darkname}`} onClick={() => { onResetOTP() }}>Reset OTP</div>
                                    <div className={`modal-2-1-left-3 ${darkname}`} onClick={() => { onResetPassword() }}>Reset Password</div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='modal-2-1-right'>
                            <div className='modal-2-1-right-box'>
                                <div>Email <span style={{ paddingLeft: "5px" }}>{item.email}</span></div>
                                <div className='modify'>modify</div>
                            </div>
                            <div className='modal-2-1-right-box'>
                                <div>Mobile <span style={{ paddingLeft: "5px" }}>{item.mobile}</span></div>
                                <div className='modify'>modify</div>
                            </div>
                        </div> */}
                    </div>
                    <div className={`hr ${darkname}`}></div>
                    <div className='modal-2-2'>
                        <div className={`table-head ${darkname}`}>
                            <div style={{ width: "70%" }}></div>
                            <div>Total Trade</div>
                            <div>Level</div>
                            <div>Binance Master %</div>
                            <div>Binance Self %</div>
                            <div>OKX Master %</div>
                            <div>OKX Self %</div>
                            <div>Bybit Master %</div>
                            <div>Bybit Self %</div>
                        </div>
                        <div className='table-body'>
                            <div className={`table-body-tr ${darkname}`}>
                                <div className={`table-body-tr-1 td ${darkname}`} style={{ width: "70%" }}>Spot</div>
                                <div className='td'>{Number(totalTrade[0]).toFixed(6)}</div>
                                <div className='td'>
                                    <div className={`member-2-select border ${darkname}`} onClick={() => setSelectStatus(e => !e)}>
                                        <div className={`member-1-select-1 ${darkname}`}>{selectStatusValue}</div>
                                        {
                                            selectStatus
                                                ?
                                                <FontAwesomeIcon icon={faSortUp} />
                                                :
                                                <FontAwesomeIcon icon={faSortDown} />
                                        }
                                        {
                                            selectStatus
                                                ?
                                                <>
                                                    <div className='modal' onClick={() => setSelectStatus(true)}></div>
                                                    <div className={`member-2-select-box ${darkname}`}>
                                                        <div onClick={() => { setSelectStatusValue("AGENT"); setSelectStatus(true) }}>AGENT</div>
                                                        <div onClick={() => { setSelectStatusValue("MASTER"); setSelectStatus(true) }}>MASTER</div>
                                                    </div>
                                                </>
                                                :
                                                ""
                                        }
                                    </div>
                                </div>
                                <div className='td'>
                                    <input value={bnspotMaster} onChange={onChangeBnSpotMaster} placeholder={`${detailData.bn_master_rebate_spot_percent * 100}`} />
                                </div>
                                <div className='td'>
                                    <input value={bnspotSelf} onChange={onChangeBnSpotSelf} placeholder={`${detailData.bn_self_rebate_spot_percent * 100}`} />
                                </div>
                                <div className='td'>
                                    <input value={okxspotMaster} onChange={onChangeOkxSpotMaster} placeholder={`${detailData.okx_master_rebate_spot_percent * 100}`} />
                                </div>
                                <div className='td'>
                                    <input value={okxspotSelf} onChange={onChangeOkxSpotSelf} placeholder={`${detailData.okx_self_rebate_spot_percent * 100}`} />
                                </div>
                                <div className='td'>
                                    <input value={byspotMaster} onChange={onChangeBySpotMaster} placeholder={`${detailData.bybit_master_rebate_spot_percent * 100}`} />
                                </div>
                                <div className='td'>
                                    <input value={byspotSelf} onChange={onChangeBySpotSelf} placeholder={`${detailData.bybit_self_rebate_spot_percent * 100}`} />
                                </div>
                            </div>
                            <div className={`table-body-tr ${darkname}`}>
                                <div className={`table-body-tr-1 td ${darkname}`} style={{ width: "70%" }}>Futures</div>
                                <div className='td'>{Number(totalTrade[1]).toFixed(6)}</div>
                                <div className='td'>
                                    {/* <div className={`member-1-select border ${darkname}`} onClick={() => setSelectStatus1(e => !e)}>
                                        <div className={`member-1-select-1 ${darkname}`}>{selectStatusValue1}</div>
                                        {
                                            selectStatus1
                                                ?
                                                <FontAwesomeIcon icon={faSortUp} />
                                                :
                                                <FontAwesomeIcon icon={faSortDown} />
                                        }
                                        {
                                            selectStatus1
                                                ? <>
                                                    <div className='modal' onClick={() => setSelectStatus1(true)}></div>
                                                    <div className={`member-1-select-box ${darkname}`}>
                                                        <div onClick={() => { setSelectStatusValue1("AGENT"); setSelectStatus1(true) }}>AGENT</div>
                                                        <div onClick={() => { setSelectStatusValue1("MASTER"); setSelectStatus1(true) }}>MASTER</div>
                                                    </div>
                                                </>
                                                :
                                                ""
                                        }
                                    </div> */}
                                </div>
                                <div className='td'>
                                    <input value={bnfuturesMaster} onChange={onChangeBnFuturesMaster} placeholder={`${detailData.bn_master_rebate_futures_percent * 100}`} />
                                </div>
                                <div className='td'>
                                    <input value={bnfuturesSelf} onChange={onChangeBnFuturesSelf} placeholder={`${detailData.bn_self_rebate_futures_percent * 100}`} />
                                </div>
                                <div className='td'>
                                    <input value={okxfuturesMaster} onChange={onChangeOkxFuturesMaster} placeholder={`${detailData.okx_master_rebate_swap_percent * 100}`} />
                                </div>
                                <div className='td'>
                                    <input value={okxfuturesSelf} onChange={onChangeOkxFuturesSelf} placeholder={`${detailData.okx_self_rebate_swap_percent * 100}`} />
                                </div>
                                <div className='td'>
                                    <input value={byfuturesMaster} onChange={onChangeByFuturesMaster} placeholder={`${detailData.bybit_master_rebate_futures_percent * 100}`} />
                                </div>
                                <div className='td'>
                                    <input value={byfuturesSelf} onChange={onChangeByFuturesSelf} placeholder={`${detailData.bybit_self_rebate_futures_percent * 100}`} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='modal-2-3'>
                        <div className={`modal-2-3-btn ${darkname}`} onClick={() => { onConfirm() }}>Confirm</div>
                        <div className={`modal-2-3-btn ${darkname}`} onClick={() => setClickId(false)}>Close</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function padZero(value) {
    return value < 10 ? `0${value}` : value;
}

function changeTime(d) {
    d.setUTCHours(0);
    d.setUTCMinutes(0);
    d.setUTCSeconds(0);
    return d.getTime()
}

const Excel = (excelData) => {
    const excelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const excelFileExtension = '.xlsx';
    const excelFileName = `CS Log_Total`;

    const ws = XLSX.utils.aoa_to_sheet([
        ["Time", "Employee", "ActivityLog", "RF-REV ID"]
    ]);
    excelData.map((data) => {
        XLSX.utils.sheet_add_aoa(
            ws,
            [
                [
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.Time },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.Employee },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.ActivityLog },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.RFREVID }
                ]
            ],
            { origin: -1 }
        );
        ws['!cols'] =
            [
                { wpx: 130 },
                { wpx: 100 },
                { wpx: 100 },
                { wpx: 100 }
            ]

        return false;
    });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelButter = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const excelFile = new Blob([excelButter], { type: excelFileType });
    FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);
};

export default Cslog;
import './css/main.css'
import { Link, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faCircleXmark, faSortDown, faSortUp, faWindowMinimize } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from 'react';
import Sidebar from '../Nav/sidebar';
import NewPage from './NewPage'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import Country from './country'
import { useDispatch, useSelector } from 'react-redux';
import { checkLogin } from '../store';
import { SyncLoader } from "react-spinners";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import { removeZeros } from './common/function'

let filter = []

function Member() {
    const [search, setSearch] = useState("")
    const [select, setSelect] = useState(false)
    const [selectValue, setSelectValue] = useState("Email")
    const [page, setPage] = useState(1)
    const [memberData, setMemberData] = useState([])
    const [memberDatalist, setMemberDataList] = useState([])
    const [reload, setReload] = useState(false)
    const [spinner, setSpinner] = useState(false)

    let dark = useSelector((state) => { return state.Darkmode.value });
    let SelectType = useSelector((state) => { return state.SelectType.value });

    const darkname = dark ? 'dark' : "";

    let dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            if (Date.now() > obj.expire) {
                localStorage.removeItem('token');
                dispatch(checkLogin(false));
                navigate('/')
            }
            else {
                dispatch(checkLogin(true));
            }
        }
        else {
            dispatch(checkLogin(false));
            navigate('/')
        }
    }, [])

    const onChangeSearch = (e) => {
        setSearch(e.target.value)
    }

    useEffect(() => {
        onSpinner()
        setPage(1)
        MemberData()
    }, [reload])

    const MemberData = async () => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get('http://211.253.38.162:10220/api/v1/member', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let tmp = response.data.data
                if (tmp) {
                    setMemberData(tmp)
                }
            }).catch(error => {
                console.error(error);
            });
        }
    }

    useEffect(() => {
        if (memberData && memberData.length > 0) {
            if (selectValue == "Email" && search.length > 0) {
                filter = memberData.filter((p) => {
                    if (p.email) return p.email.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
            }
            else if (selectValue == "Mobile" && search.length > 0) {
                filter = memberData.filter((p) => {
                    if (p.mobile) return p.mobile.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
            }
            else if (selectValue == "RF-Rev ID" && search.length > 0) {
                filter = memberData.filter((p) => {
                    if (p.uid) return String(p.uid).toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
            }
            else if (selectValue == "EXUID" && search.length > 0) {
                filter = memberData.filter((p) => {
                    return (p.binance_exuid && p.binance_exuid.toLocaleLowerCase().includes(search.toLocaleLowerCase()) || p.okx_exuid && p.okx_exuid.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
                })
            }
            else {
                filter = memberData
            }
            setMemberDataList(filter)
        }
    }, [memberData])

    const onSpinner = () => {
        setSpinner(true)
        setTimeout(() => {
            setSpinner(false)
        }, 500)
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            setReload(e => !e)
        }
    };

    const excelData = () => {
        let arr = []
        if (memberDatalist) {
            for (let i = 0; i < memberDatalist.length; i++) {
                let t = memberDatalist[i]
                let country = ""
                if (t.country_code) {
                    for (let i = 0; i < Country.length; i++) {
                        if (Country[i].value === t.country_code) country = Country[i].code
                    }
                }
                arr.push({
                    "RFRevID": t.uid,
                    "Email": t.email ? t.email : "-",
                    "Country": country ? country : "-",
                    "Mobile": t.mobile ? t.mobile : "-",
                    "Level": t.level,
                    "Master": t.level == "AGENT" ? t.master ? t.master : "0" : "N/A",
                    "Status": t.status,
                    "BnEXUID": t.binance_exuid ? t.binance_exuid : "-",
                    "OKXEXUID": t.okx_exuid ? t.okx_exuid : "_",
                    "ByEXUID": ""
                })
            }
        }
        Excel(arr, SelectType)
    }

    let slice_data = memberDatalist.slice((page - 1) * 20, (page) * 20)

    return (
        <div className='main'>
            <Sidebar page={0} />
            <div className={`main-box ${darkname}`}>
                <div className='member-1'>
                    <div className={`member-2-search ${darkname}`}>
                        <div className={`member-1-select ${darkname}`} onClick={() => setSelect(e => !e)}>
                            <div className={`member-1-select-1 ${darkname}`}>{selectValue}</div>
                            {
                                select
                                    ?
                                    <FontAwesomeIcon icon={faSortUp} />
                                    :
                                    <FontAwesomeIcon icon={faSortDown} />
                            }
                            {
                                select
                                    ? <>
                                        <div className='modal' onClick={() => setSelect(true)}></div>
                                        <div className={`member-1-select-box ${darkname}`}>
                                            <div onClick={() => { setSelectValue("Email"); setSelect(true) }}>Email</div>
                                            <div onClick={() => { setSelectValue("Mobile"); setSelect(true) }}>Mobile</div>
                                            <div onClick={() => { setSelectValue("RF-Rev ID"); setSelect(true) }}>RF-Rev ID</div>
                                            <div onClick={() => { setSelectValue("EXUID"); setSelect(true) }}>EXUID</div>
                                        </div>
                                    </>
                                    :
                                    ""
                            }
                        </div>
                        <FontAwesomeIcon icon={faWindowMinimize} className='line' />
                        <div className={`member-2-search-1 ${darkname}`}>
                            <input value={search} onChange={onChangeSearch} placeholder='Search' onKeyDown={handleKeyPress} />
                            <FontAwesomeIcon icon={faMagnifyingGlass} className='member-1-search-ico-3' />
                            {
                                search.length > 0
                                    ?
                                    <FontAwesomeIcon icon={faCircleXmark} className='member-1-search-ico-2' onClick={() => setSearch("")} />
                                    :
                                    ""
                            }
                        </div>
                    </div>
                    <div className={`search-btn ${darkname}`} onClick={() => setReload(e => !e)}>Search</div>
                </div>
                <div className='tradingType-1' style={{ marginTop: "10px" }} >
                    <div className={`tradingType-1-2 ${darkname}`} onClick={() => excelData()}>Excel download</div>
                </div>
                <div className={`member-2 ${darkname}`}>
                    <div className={`table-head ${darkname}`}>
                        <div style={{ width: "50%" }}>RF-Rev ID</div>
                        {SelectType != 2 ? <div>Email</div> : ""}
                        <div style={{ width: "30%" }}>Country</div>
                        {SelectType != 2 ? <div style={{ width: "50%" }}>Mobile</div> : ""}
                        <div style={{ width: "50%" }}>Level</div>
                        <div style={{ width: "50%" }}>Master</div>
                        <div style={{ width: "50%" }}>Status</div>
                        <div style={{ width: "70%" }}>Binance EXUID</div>
                        <div style={{ width: "70%" }}>OKX EXUID</div>
                        <div style={{ width: "70%" }}>Bybit EXUID</div>
                    </div>
                    <div className='table-body' style={{ minHeight: "600px" }}>
                        {
                            spinner
                                ?
                                <div className='spinner-modal'>
                                    <SyncLoader color="#F5AC1D" />
                                </div>
                                :
                                slice_data.length === 0
                                    ?
                                    <div className='data-nofound'>
                                        <img src='/img/img_nofound.png'></img>
                                        <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                        <p>NO RECORDS FOUND.</p>
                                    </div>
                                    :
                                    <>
                                        {
                                            slice_data.map(function (a, i) {
                                                return (
                                                    <MemberItem key={i} item={a} darkname={darkname} dark={dark} reload={reload} setReload={setReload} />
                                                )
                                            })
                                        }
                                        <NewPage initialData={memberDatalist} page={page} setPage={setPage} maxFirstPage={20} />
                                    </>

                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

function MemberItem({ item, darkname, dark, reload, setReload }) {
    let SelectType = useSelector((state) => { return state.SelectType.value });
    const [clickId, setClickId] = useState(false)
    const [clickMaster, setClickMaster] = useState(false)
    const [clickEXUID, setClickEXUID] = useState(false)
    const [EXUIDtype, setEXUIDtype] = useState(0)
    const [country, setCountry] = useState("")

    useEffect(() => {
        setCountry("")
        if (item.country_code) {
            for (let i = 0; i < Country.length; i++) {
                if (Country[i].value === item.country_code) setCountry(Country[i].code)
            }
        }
    }, [item])

    return (
        <>
            <div className={`table-body-tr ${darkname}`}>
                <div className='onclick td' style={{ width: "50%" }} onClick={() => setClickId(true)}>{item.uid}</div>
                {SelectType != 2 ? <div className='td'>{item.email ? item.email : '-'}</div> : ""}
                <div className='td' style={{ width: "30%" }}>{country && country.length > 0 ? country : '-'}</div>
                {SelectType != 2 ? <div className='td' style={{ width: "50%" }}>{item.mobile ? item.mobile : '-'}</div> : ""}
                <div className='td' style={{ width: "50%" }}>{item.level}</div>
                {
                    item.level == "AGENT"
                        ?
                        <div className='onclick td' style={{ width: "50%" }} onClick={() => setClickMaster(true)}>{item.master ? item.master : "0"}</div>
                        :
                        <div className='td' style={{ width: "50%" }} >N/A</div>
                }
                <div className='onclick td' style={{ width: "50%" }}>{item.status}</div>
                <div className='onclick td' style={{ width: "70%" }} onClick={() => { setClickEXUID(item.binance_exuid ? true : false); setEXUIDtype(0) }}>{item.binance_exuid ? item.binance_exuid : "-"}</div>
                <div className='onclick td' style={{ width: "70%" }} onClick={() => { setClickEXUID(item.okx_exuid ? true : false); setEXUIDtype(1) }}>{item.okx_exuid ? item.okx_exuid : "-"}</div>
                <div className='onclick td' style={{ width: "70%" }} onClick={() => { setClickEXUID(item.bybit_id ? true : false); setEXUIDtype(2) }}>{item.bybit_id ? item.bybit_id : "-"}</div>
            </div>
            {
                clickId
                    ?
                    <ModalId item={item} setClickId={setClickId} darkname={darkname} setReload={setReload} />
                    :
                    ""
            }
            {
                clickMaster
                    ?
                    <ModalMaster item={item} setClickMaster={setClickMaster} darkname={darkname} reload={reload} setReload={setReload} />
                    :
                    ""
            }
            {
                clickEXUID
                    ?
                    <ModalEXUID item={item} setClickEXUID={setClickEXUID} EXUIDtype={EXUIDtype} darkname={darkname} dark={dark} />
                    :
                    ""
            }
        </>
    )
}

function ModalMaster({ item, setClickMaster, darkname, reload, setReload }) {
    const [code, setCode] = useState("")

    const onModifyCode = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9]/g, "")
        setCode(onlyNumber)
    }

    const onChangeMaster = async () => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;
            await axios.get('http://211.253.38.162:10220/api/v1/member/update/referral', {
                params: {
                    id: item.uid,
                    referral: code
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                if (response.data.code > 0) {
                    setClickMaster(false);
                    setReload(e => !e)
                }
            }).catch(error => {
                console.error(error);
            });
        }
    }


    const handleEsc = (event) => {
        if (event.key === 'Escape') {
            setClickMaster(false);
        }
    };

    document.addEventListener('keydown', handleEsc);


    return (
        <div className='modal'>
            <div className='modal-box'>
                <div className={`modal-5 ${darkname}`}>
                    <div className={`modal-1-tit ${darkname}`}>Update Referral Code</div>
                    <div className='password'>
                        <div style={{ width: "100%", textAlign: "left" }}>
                            <div style={{ fontSize: "14px", paddingBottom: "3px" }}>Current Referral Code</div>
                            <div className={`password-1 ${darkname}`}>
                                <div>{item.master ? item.master : "0"}</div>
                            </div>
                        </div>
                        <div style={{ width: "100%", textAlign: "left" }}>
                            <div style={{ fontSize: "14px", paddingBottom: "3px" }}>New Referral Code</div>
                            <div className={`password-1 ${darkname}`}>
                                <input value={code} onChange={onModifyCode} maxLength={6} placeholder='New Referral Code' />
                            </div>
                        </div>
                    </div>
                    <div className='password-2' style={{ marginTop: "30px" }}>
                        <div className={`password-2-1 ${darkname}`} onClick={() => onChangeMaster()}>Confirm</div>
                        <div className={`password-2-1 ${darkname}`} onClick={() => setClickMaster(false)}>Cancel</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function ModalEXUID({ item, setClickEXUID, EXUIDtype, darkname }) {
    const [btn, setBtn] = useState(0)
    const [dateBtn, setDateBtn] = useState(0)
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());
    const [page, setPage] = useState(1)
    const [newpage, setNewPage] = useState(1)
    const [totalpage, setTotalpage] = useState(1)
    const [depositData, setDepositData] = useState([])
    const [withdrawData, setWithdrawData] = useState([])
    const [tradingData, setTradingData] = useState([])
    const [tradingList, setTradingList] = useState([])
    const [orderData, setOrderData] = useState([])
    const [orderList, setOrderList] = useState([])

    const [spinner, setSpinner] = useState(false)

    const handleEsc = (event) => {
        if (event.key === 'Escape') {
            setClickEXUID(false);
        }
    };

    document.addEventListener('keydown', handleEsc);

    useEffect(() => {
        setSpinner(true)
        setNewPage(1)
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            if (btn === 0) TradingData(token)
            else if (btn === 1) OrderData(token)
            else if (btn === 2) DepositData(token)
            else if (btn === 3) WithdrawData(token)
        }
    }, [selectedDate, selectedDate1, btn])

    const DepositData = async (token) => {
        await axios.get(`http://211.253.38.162:10220/api/v1/${EXUIDtype === 0 ? "bn" : EXUIDtype === 1 ? "okx" : "bybit"}/user/deposit/history`, {
            params: {
                start: selectedDate.getTime(),
                end: selectedDate1.getTime(),
                uid: item.uid
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            let tmp = response.data.data
            if (tmp) {
                setDepositData(tmp)
                setSpinner(false)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const WithdrawData = async (token) => {
        await axios.get(`http://211.253.38.162:10220/api/v1/${EXUIDtype === 0 ? "bn" : EXUIDtype === 1 ? "okx" : "bybit"}/user/withdrawal/history`, {
            params: {
                start: selectedDate.getTime(),
                end: selectedDate1.getTime(),
                uid: item.uid
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            let tmp = response.data.data
            if (tmp) {
                setWithdrawData(tmp)
                setSpinner(false)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const TradingData = async (token) => {
        await axios.get(`http://211.253.38.162:10220/api/v1/${EXUIDtype === 0 ? "bn" : EXUIDtype === 1 ? "okx" : "bybit"}/user/trade-history`, {
            params: {
                id: item.uid
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            let tmp = response.data.data
            if (tmp) {
                setTradingData(tmp)
                setSpinner(false)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const OrderData = async (token) => {
        await axios.get(`http://211.253.38.162:10220/api/v1/${EXUIDtype === 0 ? "bn" : EXUIDtype === 1 ? "okx" : "bybit"}/user/order-history`, {
            params: {
                id: item.uid
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            let tmp = response.data.data
            if (tmp) {
                setOrderData(tmp)
                setSpinner(false)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    useEffect(() => {
        let arr = []

        if (tradingData && tradingData.length > 0) {
            for (let i = 0; i < tradingData.length; i++) {
                if (EXUIDtype === 2) {
                    if (Math.floor(changeTime(selectedDate) / 1000) * 1000 <= new Date(tradingData[i].time).getTime() && new Date(tradingData[i].time).getTime() <= Math.floor(changeTime(selectedDate1) / 1000) * 1000 + 86399999) {
                        arr.push(tradingData[i])
                    }
                }
                else {
                    if (Math.floor(changeTime(selectedDate) / 1000) * 1000 <= new Date(tradingData[i].fillTime).getTime() && new Date(tradingData[i].fillTime).getTime() <= Math.floor(changeTime(selectedDate1) / 1000) * 1000 + 86399999) {
                        arr.push(tradingData[i])
                    }
                }
            }

            setTradingList(arr)
        }
    }, [selectedDate, selectedDate1, tradingData])

    useEffect(() => {
        let arr = []

        if (orderData && orderData.length > 0) {
            for (let i = 0; i < orderData.length; i++) {
                if (EXUIDtype === 2) {
                    if (Math.floor(changeTime(selectedDate) / 1000) * 1000 <= new Date(orderData[i].time).getTime() && new Date(orderData[i].time).getTime() <= Math.floor(changeTime(selectedDate1) / 1000) * 1000 + 86399999) {
                        arr.push(orderData[i])
                    }
                }
                else {
                    if (Math.floor(changeTime(selectedDate) / 1000) * 1000 <= new Date(orderData[i].fillTime).getTime() && new Date(orderData[i].fillTime).getTime() <= Math.floor(changeTime(selectedDate1) / 1000) * 1000 + 86399999) {
                        arr.push(orderData[i])
                    }
                }
            }

            setOrderList(arr)
        }
    }, [selectedDate, selectedDate1, orderData])

    let slice_data_trade = tradingList.slice((newpage - 1) * 10, (newpage) * 10)
    let slice_data_order = orderList.slice((newpage - 1) * 10, (newpage) * 10)
    let slice_data_deposit = depositData.slice((newpage - 1) * 10, (newpage) * 10)
    let slice_data_withdraw = withdrawData.slice((newpage - 1) * 10, (newpage) * 10)

    return (
        <div className='modal'>
            <div className={`modal-box`}>
                <div className={`modal-3 ${darkname}`}>
                    <div className='modal-1-tit'>{EXUIDtype === 0 ? "Binance" : EXUIDtype === 1 ? "OKX" : "Bybit"} History</div>
                    <div className='modal-3-1'>
                        <div className={`modal-3-1-left ${darkname}`}>
                            <div className={btn === 0 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => { setBtn(0) }}>Trading</div>
                            <div className={btn === 1 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => { setBtn(1) }}>Order</div>
                            <div className={btn === 2 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => { setBtn(2) }}>Deposit</div>
                            <div className={btn === 3 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => { setBtn(3) }}>Withdrawal</div>
                        </div>
                        <div className={`modal-3-1-left ${darkname}`}>
                            <div className={dateBtn === 0 ? "modal-3-1-left-1 on flex" : "modal-3-1-left-1 flex"} onClick={() => setDateBtn(0)}>
                                <DatePicker
                                    dateFormat='yyyy-MM-dd' // 날짜 형태
                                    shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                                    minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                                    maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                                    selected={selectedDate}
                                    onChange={(date) => { setSelectedDate(date); setSelectedDate1(date) }}
                                />
                            </div>
                            <div className={dateBtn === 1 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                                setDateBtn(1);
                                setSelectedDate((new Date())); setSelectedDate1((new Date()))
                            }}>Today</div>
                            <div className={dateBtn === 2 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                                setDateBtn(2);
                                setSelectedDate(new Date((new Date()).getTime() - 604800000)); setSelectedDate1(new Date())
                            }}>7 Days</div>
                            <div className={dateBtn === 3 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                                setDateBtn(3);
                                setSelectedDate(new Date((new Date()).getTime() - 2592000000)); setSelectedDate1(new Date())
                            }}>30 Days</div>
                            <div className={dateBtn === 4 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                                setDateBtn(4);
                                setSelectedDate(new Date((new Date()).getTime() - 7776000000)); setSelectedDate1(new Date())
                            }}>90 Days</div>
                            <div className={dateBtn === 5 ? "modal-3-1-left-1 on flex" : "modal-3-1-left-1 flex"} onClick={() => setDateBtn(5)}>
                                <DatePicker
                                    dateFormat='yyyy-MM-dd' // 날짜 형태
                                    shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                                    minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                                    maxDate={selectedDate1} // maxDate 이후 날짜 선택 불가
                                    selected={selectedDate}
                                    onChange={(date) => setSelectedDate(date)}
                                />
                                <div>~</div>
                                <DatePicker
                                    dateFormat='yyyy-MM-dd' // 날짜 형태
                                    shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                                    minDate={selectedDate} // minDate 이전 날짜 선택 불가
                                    maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                                    selected={selectedDate1}
                                    onChange={(date) => setSelectedDate1(date)}
                                />
                            </div>
                        </div>
                    </div>
                    {
                        btn === 0
                            ?
                            <div className='modal-3-2'>
                                <div className={`table-head ${darkname}`}>
                                    <div>Time</div>
                                    <div style={{ width: "70%" }}>Symbol</div>
                                    <div style={{ width: "50%" }}>Type</div>
                                    <div style={{ width: "50%" }}>Side</div>
                                    <div>Price</div>
                                    <div>Quantity</div>
                                    <div>Fee</div>
                                    <div>Commission</div>
                                </div>
                                <div className='table-body' style={{ minHeight: "470px" }}>
                                    {
                                        spinner
                                            ?
                                            <div className='spinner-modal'>
                                                <SyncLoader color="#F5AC1D" />
                                            </div>
                                            :
                                            slice_data_trade && slice_data_trade.length === 0
                                                ?
                                                <div className='data-nofound'>
                                                    <img src='/img/img_nofound.png'></img>
                                                    <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                                    <p>NO RECORDS FOUND.</p>
                                                </div>
                                                :
                                                <>
                                                    {
                                                        slice_data_trade.map(function (a, i) {
                                                            return (
                                                                <>
                                                                    {
                                                                        EXUIDtype === 2
                                                                            ?
                                                                            <ModalEXUIDItem_Trade_Bybit key={i} item={a} darkname={darkname} />
                                                                            :
                                                                            <ModalEXUIDItem_Trade key={i} item={a} darkname={darkname} />
                                                                    }
                                                                </>
                                                            )
                                                        })
                                                    }
                                                    <NewPage initialData={tradingList} page={newpage} setPage={setNewPage} maxFirstPage={10} />
                                                </>
                                    }
                                </div>
                            </div>
                            :
                            btn === 1
                                ?
                                <div className='modal-3-2'>
                                    <div className={`table-head ${darkname}`}>
                                        <div>Time</div>
                                        <div style={{ width: "70%" }}>Symbol</div>
                                        <div style={{ width: "50%" }}>Type</div>
                                        <div style={{ width: "50%" }}>Side</div>
                                        <div>Price</div>
                                        <div>Quantity</div>
                                        <div>Fee</div>
                                        <div>Commission</div>
                                        <div>State</div>
                                    </div>
                                    <div className='table-body' style={{ minHeight: "470px" }}>
                                        {
                                            spinner
                                                ?
                                                <div className='spinner-modal'>
                                                    <SyncLoader color="#F5AC1D" />
                                                </div>
                                                :
                                                slice_data_order && slice_data_order.length === 0
                                                    ?
                                                    <div className='data-nofound'>
                                                        <img src='/img/img_nofound.png'></img>
                                                        <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                                        <p>NO RECORDS FOUND.</p>
                                                    </div>
                                                    :
                                                    <>
                                                        {
                                                            slice_data_order.map(function (a, i) {
                                                                return (
                                                                    <>
                                                                        {
                                                                            EXUIDtype === 2
                                                                                ?
                                                                                <ModalEXUIDItem_Order_Bybit key={i} item={a} darkname={darkname} />
                                                                                :
                                                                                <ModalEXUIDItem_Order key={i} item={a} darkname={darkname} />
                                                                        }
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                        <NewPage initialData={orderList} page={newpage} setPage={setNewPage} maxFirstPage={10} />
                                                    </>
                                        }
                                    </div>
                                </div>
                                :
                                btn === 2
                                    ?
                                    <div className='modal-3-2'>
                                        <div className={`table-head ${darkname}`}>
                                            <div style={{ width: "50%" }}>Date</div>
                                            <div>TX</div>
                                            <div style={{ width: "40%" }}>Amount</div>
                                            <div style={{ width: "30%" }}>Asset</div>
                                            <div style={{ width: "30%" }}>Network</div>
                                            <div>Wallet</div>
                                        </div>
                                        <div className='table-body' style={{ minHeight: "470px" }}>
                                            {
                                                spinner
                                                    ?
                                                    <div className='spinner-modal'>
                                                        <SyncLoader color="#F5AC1D" />
                                                    </div>
                                                    :
                                                    slice_data_deposit && slice_data_deposit.length === 0
                                                        ?
                                                        <div className='data-nofound'>
                                                            <img src='/img/img_nofound.png'></img>
                                                            <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                                            <p>NO RECORDS FOUND.</p>
                                                        </div>
                                                        :
                                                        <>
                                                            {
                                                                slice_data_deposit.map(function (a, i) {
                                                                    return (
                                                                        <DepositItem key={i} item={a} darkname={darkname} />
                                                                    )
                                                                })
                                                            }
                                                            <NewPage initialData={depositData} page={newpage} setPage={setNewPage} maxFirstPage={10} />
                                                        </>
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div className='modal-3-2'>
                                        <div className={`table-head ${darkname}`}>
                                            <div style={{ width: "50%" }}>Date</div>
                                            <div>TX</div>
                                            <div style={{ width: "40%" }}>Amount</div>
                                            <div style={{ width: "30%" }}>Asset</div>
                                            <div style={{ width: "30%" }}>Network</div>
                                            <div>Wallet</div>
                                        </div>
                                        <div className='table-body' style={{ minHeight: "470px" }}>
                                            {
                                                spinner
                                                    ?
                                                    <div className='spinner-modal'>
                                                        <SyncLoader color="#F5AC1D" />
                                                    </div>
                                                    :
                                                    slice_data_withdraw && slice_data_withdraw.length === 0
                                                        ?
                                                        <div className='data-nofound'>
                                                            <img src='/img/img_nofound.png'></img>
                                                            <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                                            <p>NO RECORDS FOUND.</p>
                                                        </div>
                                                        :
                                                        <>
                                                            {
                                                                slice_data_withdraw.map(function (a, i) {
                                                                    return (
                                                                        <WithdrawItem key={i} item={a} darkname={darkname} />
                                                                    )
                                                                })
                                                            }
                                                            <NewPage initialData={withdrawData} page={newpage} setPage={setNewPage} maxFirstPage={10} />
                                                        </>
                                            }
                                        </div>
                                    </div>
                    }
                    <div className='modal-2-3'>
                        <div className={`modal-2-3-btn ${darkname}`} onClick={() => setClickEXUID(false)}>Close</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function ModalEXUIDItem_Trade({ item, darkname }) {
    let arr = item.fillTime.split('T')

    return (
        <div className={`table-body-tr ${darkname}`}>
            <div className='td'>{arr ? arr[0] : item.date}</div>
            <div className='td' style={{ width: "70%" }}>{item.asset}</div>
            <div className='td' style={{ width: "50%" }}>{item.market}</div>
            <div className='td' style={{ width: "50%" }}>{item.side}</div>
            <div className='right td'>{removeZeros(item.price)}</div>
            <div className='right td'>{removeZeros(item.quantity)}</div>
            <div className='right td'>{Math.abs(item.fee)}</div>
            <div className='right td'>-</div>
        </div>
    )
}

function ModalEXUIDItem_Order({ item, darkname }) {
    let arr = item.fillTime.split('T')

    return (
        <div className={`table-body-tr ${darkname}`}>
            <div className='td'>{arr ? arr[0] : item.date}</div>
            <div className='td' style={{ width: "70%" }}>{item.asset}</div>
            <div className='td' style={{ width: "50%" }}>{item.market}</div>
            <div className='td' style={{ width: "50%" }}>{item.side}</div>
            <div className='right td'>{Number(item.price) === 0 ? '-' : removeZeros(item.price)}</div>
            <div className='right td'>{Number(item.quantity) === 0 ? '-' : removeZeros(item.quantity)}</div>
            <div className='right td'>{Number(item.fee) === 0 ? '-' : Math.abs(item.fee)}</div>
            <div className='right td'>-</div>
            <div className='td'>{item.STATUS}</div>
        </div>
    )
}


function ModalEXUIDItem_Trade_Bybit({ item, darkname }) {

    return (
        <div className={`table-body-tr ${darkname}`}>
            <div className='td'>{item.time}</div>
            <div className='td' style={{ width: "70%" }}>{item.symbol}</div>
            <div className='td' style={{ width: "50%" }}>{item.category}</div>
            <div className='td' style={{ width: "50%" }}>{item.side}</div>
            <div className='right td'>{removeZeros(item.price)}</div>
            <div className='right td'>{removeZeros(item.qty)}</div>
            <div className='right td'>{Math.abs(item.fee)}</div>
            <div className='right td'>-</div>
        </div>
    )
}

function ModalEXUIDItem_Order_Bybit({ item, darkname }) {

    return (
        <div className={`table-body-tr ${darkname}`}>
            <div className='td'>{item.time}</div>
            <div className='td' style={{ width: "70%" }}>{item.symbol}</div>
            <div className='td' style={{ width: "50%" }}>{item.type}</div>
            <div className='td' style={{ width: "50%" }}>{item.side}</div>
            <div className='right td'>{Number(item.price) === 0 ? '-' : removeZeros(item.price)}</div>
            <div className='right td'>{Number(item.qty) === 0 ? '-' : removeZeros(item.qty)}</div>
            <div className='right td'>{Number(item.fee) === 0 ? '-' : Math.abs(item.fee)}</div>
            <div className='right td'>-</div>
            <div className='td'>{item.state}</div>
        </div>
    )
}

function DepositItem({ item, darkname }) {
    let tmpdate = item.date.split('T')
    let tmpdate1 = tmpdate[1].split('.000Z')

    const [mouseState, setMouseState] = useState(false);
    const [mouseState1, setMouseState1] = useState(false);

    const onMouseEnter = () => {
        setMouseState(true);
    };
    const onMouseLeave = () => {
        setMouseState(false);
    };
    const onMouseEnter1 = () => {
        setMouseState1(true);
    };
    const onMouseLeave1 = () => {
        setMouseState1(false);
    };

    return (
        <>
            <div className={`table-body-tr ${darkname}`}>
                <div className='td' style={{ width: "50%" }}>{`${tmpdate[0]} ${tmpdate1[0]}`}</div>
                <div className='overflow-hidden left td' onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>{item.txId ? item.txId : "-"}
                    {
                        mouseState && item.txId
                            ?
                            <div className={`overflow-box ${darkname}`} style={{ left: "15%" }}>{item.txId}</div>
                            :
                            ""
                    }
                </div>
                <div className='right td' style={{ width: "40%" }}>{Number(item.amount)}</div>
                <div className='td' style={{ width: "30%" }}>{item.asset}</div>
                <div className='td' style={{ width: "30%" }}>{item.network}</div>
                <div className='overflow-hidden left td' onMouseEnter={onMouseEnter1} onMouseLeave={onMouseLeave1}>{item.wallet ? item.wallet : "-"}
                    {
                        mouseState1 && item.wallet
                            ?
                            <div className={`overflow-box ${darkname}`} style={{ right: "0" }}>{item.wallet}</div>
                            :
                            ""
                    }
                </div>
            </div>
        </>
    )
}

function WithdrawItem({ item, darkname }) {
    let tmpdate = item.withdrawal_time.split('T')
    let tmpdate1 = tmpdate[1].split('.000Z')

    const [mouseState, setMouseState] = useState(false);
    const [mouseState1, setMouseState1] = useState(false);

    const onMouseEnter = () => {
        setMouseState(true);
    };
    const onMouseLeave = () => {
        setMouseState(false);
    };
    const onMouseEnter1 = () => {
        setMouseState1(true);
    };
    const onMouseLeave1 = () => {
        setMouseState1(false);
    };

    return (
        <>
            <div className={`table-body-tr ${darkname}`}>
                <div className='td' style={{ width: "50%" }}>{`${tmpdate[0]} ${tmpdate1[0]}`}</div>
                <div className='overflow-hidden left td' onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>{item.txid ? item.txid : "-"}
                    {
                        mouseState && item.txid
                            ?
                            <div className={`overflow-box ${darkname}`} style={{ left: "15%" }}>{item.txid}</div>
                            :
                            ""
                    }
                </div>
                <div className='right td' style={{ width: "40%" }}>{Number(item.amount)}</div>
                <div className='td' style={{ width: "30%" }}>{item.asset}</div>
                <div className='td' style={{ width: "30%" }}>{item.network}</div>
                <div className='overflow-hidden left td' onMouseEnter={onMouseEnter1} onMouseLeave={onMouseLeave1}>{item.wallet ? item.wallet : "-"}
                    {
                        mouseState1 && item.wallet
                            ?
                            <div className={`overflow-box ${darkname}`} style={{ right: "0" }}>{item.wallet}</div>
                            :
                            ""
                    }
                </div>
            </div>
        </>
    )
}

function ModalId({ item, setClickId, darkname, setReload }) {
    let SelectType = useSelector((state) => { return state.SelectType.value });

    const [detailData, setDetailData] = useState([])
    const [totalTrade, settotalTrade] = useState([0, 0])
    const [signdate, setSigndate] = useState("")
    const [lastsigndate, setLastSigndate] = useState("")
    const [email, setEmail] = useState(item.email ? item.email : "")
    const [mobile, setMobile] = useState(item.mobile ? item.mobile : "")
    const [phone, setPhone] = useState(item.country_code ? item.country_code : "")

    const [bnspotMaster, setBnSpotMaster] = useState("0")
    const [bnfuturesMaster, setBnFuturesMaster] = useState("0")
    const [bnspotSelf, setBnSpotSelf] = useState("0")
    const [bnfuturesSelf, setBnFuturesSelf] = useState("0")
    const [okxspotMaster, setOkxSpotMaster] = useState("0")
    const [okxfuturesMaster, setOkxFuturesMaster] = useState("0")
    const [okxspotSelf, setOkxSpotSelf] = useState("0")
    const [okxfuturesSelf, setOkxFuturesSelf] = useState("0")
    const [byspotMaster, setBySpotMaster] = useState("0")
    const [byfuturesMaster, setByFuturesMaster] = useState("0")
    const [byspotSelf, setBySpotSelf] = useState("0")
    const [byfuturesSelf, setByFuturesSelf] = useState("0")

    const [selectStatus, setSelectStatus] = useState(false)
    const [selectStatusValue, setSelectStatusValue] = useState(item.level)

    const [selectStatus1, setSelectStatus1] = useState(false)
    const [selectStatusValue1, setSelectStatusValue1] = useState(item.level)

    const onChangeBnSpotMaster = (e) => {
        const { value } = e.target;
        let onlyNumber = value.replace(/[^0-9]/g, '');
        if (Number(onlyNumber > 100)) setBnSpotMaster("100");
        else setBnSpotMaster(onlyNumber);
    }
    const onChangeBnFuturesMaster = (e) => {
        const { value } = e.target;
        let onlyNumber = value.replace(/[^0-9]/g, '');
        if (Number(onlyNumber > 100)) setBnFuturesMaster("100");
        else setBnFuturesMaster(onlyNumber);
    }
    const onChangeBnSpotSelf = (e) => {
        const { value } = e.target;
        let onlyNumber = value.replace(/[^0-9]/g, '');
        if (Number(onlyNumber > 100)) setBnSpotSelf("100");
        else setBnSpotSelf(onlyNumber);
    }
    const onChangeBnFuturesSelf = (e) => {
        const { value } = e.target;
        let onlyNumber = value.replace(/[^0-9]/g, '');
        if (Number(onlyNumber > 100)) setBnFuturesSelf("100");
        else setBnFuturesSelf(onlyNumber);
    }
    const onChangeOkxSpotMaster = (e) => {
        const { value } = e.target;
        let onlyNumber = value.replace(/[^0-9]/g, '');
        if (Number(onlyNumber > 100)) setOkxSpotMaster("100");
        else setOkxSpotMaster(onlyNumber);
    }
    const onChangeOkxFuturesMaster = (e) => {
        const { value } = e.target;
        let onlyNumber = value.replace(/[^0-9]/g, '');
        if (Number(onlyNumber > 100)) setOkxFuturesMaster("100");
        else setOkxFuturesMaster(onlyNumber);
    }
    const onChangeOkxSpotSelf = (e) => {
        const { value } = e.target;
        let onlyNumber = value.replace(/[^0-9]/g, '');
        if (Number(onlyNumber > 100)) setOkxSpotSelf("100");
        else setOkxSpotSelf(onlyNumber);
    }
    const onChangeOkxFuturesSelf = (e) => {
        const { value } = e.target;
        let onlyNumber = value.replace(/[^0-9]/g, '');
        if (Number(onlyNumber > 100)) setOkxFuturesSelf("100");
        else setOkxFuturesSelf(onlyNumber);
    }
    const onChangeBySpotMaster = (e) => {
        const { value } = e.target;
        let onlyNumber = value.replace(/[^0-9]/g, '');
        if (Number(onlyNumber > 100)) setBySpotMaster("100");
        else setBySpotMaster(onlyNumber);
    }
    const onChangeByFuturesMaster = (e) => {
        const { value } = e.target;
        let onlyNumber = value.replace(/[^0-9]/g, '');
        if (Number(onlyNumber > 100)) setByFuturesMaster("100");
        else setByFuturesMaster(onlyNumber);
    }
    const onChangeBySpotSelf = (e) => {
        const { value } = e.target;
        let onlyNumber = value.replace(/[^0-9]/g, '');
        if (Number(onlyNumber > 100)) setBySpotSelf("100");
        else setBySpotSelf(onlyNumber);
    }
    const onChangeByFuturesSelf = (e) => {
        const { value } = e.target;
        let onlyNumber = value.replace(/[^0-9]/g, '');
        if (Number(onlyNumber > 100)) setByFuturesSelf("100");
        else setByFuturesSelf(onlyNumber);
    }

    // 유효성검사
    const [checkemail, setCheckEmail] = useState(false);
    const [checkmobile, setCheckMobile] = useState(false)

    const emailRegEx = /^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/i;
    const regex5 = /^[1-9][0-9]{8,10}$/;

    useEffect(() => {
        emailCheck(email)
        mobileCheck(mobile)
    }, [])

    const onChangeEmail = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^\\!-z]/gi, "")
        setEmail(onlyNumber);
        emailCheck(onlyNumber)
    }

    const onChangeMobile = (e) => {
        const { value } = e.target;
        let onlyNumber = value.replace(/[^0-9]/g, '');
        if (onlyNumber.length > 0 && onlyNumber.charAt(0) == '0') {
            onlyNumber = onlyNumber.substring(1);
        }
        setMobile(onlyNumber);
        mobileCheck(onlyNumber);
    }

    const onChangePhone = (e) => {
        setPhone(e.target.value);
    }

    const emailCheck = (username) => {
        if (emailRegEx.test(username)) setCheckEmail(true)
        else setCheckEmail(false);
    }

    const mobileCheck = (username) => {
        if (regex5.test(username)) setCheckMobile(true)
        else setCheckMobile(false);
    }

    useEffect(() => {
        MemberDetailData()
    }, [])

    const MemberDetailData = async () => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get('http://211.253.38.162:10220/api/v1/member/detail', {
                params: {
                    uid: item.uid
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let tmp = response.data.data
                console.log(response.data)
                if (tmp) {
                    if (tmp?.regist_date) {
                        let tmpdate = tmp.regist_date.split("T")
                        let tmpdate1 = tmpdate[1].split(".000Z")
                        let formattedDate = `${tmpdate[0]} ${tmpdate1[0]}`
                        setSigndate(formattedDate)
                    }

                    if (tmp?.last_access_date) {
                        let last_tmpdate = tmp.last_access_date.split("T")
                        let last_tmpdate1 = last_tmpdate[1].split(".000Z")
                        let last_formattedDate = `${last_tmpdate[0]} ${last_tmpdate1[0]}`
                        setLastSigndate(last_formattedDate)
                    }

                    setDetailData(tmp)
                    settotalTrade([tmp.total_spot, tmp.total_futures])

                    setBnSpotMaster(tmp.bn_master_rebate_spot_percent * 100)
                    setBnFuturesMaster(tmp.bn_master_rebate_futures_percent * 100)
                    setBnSpotSelf(tmp.bn_self_rebate_spot_percent * 100)
                    setBnFuturesSelf(tmp.bn_self_rebate_futures_percent * 100)
                    setOkxSpotMaster(tmp.okx_master_rebate_spot_percent * 100)
                    setOkxFuturesMaster(tmp.okx_master_rebate_swap_percent * 100)
                    setOkxSpotSelf(tmp.okx_self_rebate_spot_percent * 100)
                    setOkxFuturesSelf(tmp.okx_self_rebate_swap_percent * 100)

                    setBySpotMaster(tmp.bybit_master_rebate_spot_percent * 100)
                    setByFuturesMaster(tmp.bybit_master_rebate_futures_percent * 100)
                    setBySpotSelf(tmp.bybit_self_rebate_spot_percent * 100)
                    setByFuturesSelf(tmp.bybit_self_rebate_futures_percent * 100)
                }
            }).catch(error => {
                console.error(error);
            });
        }
    }

    const onChangeMasterData = async (e) => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.post(`http://211.253.38.162:10220/api/v1/update/user`, {
                id: item.uid,
                level: e,
                okx_master_rebate_spot_percent: Number(okxspotMaster),
                okx_master_rebate_swap_percent: Number(okxfuturesMaster),
                okx_self_rebate_spot_percent: Number(okxspotSelf),
                okx_self_rebate_swap_percent: Number(okxfuturesSelf),
                bn_master_rebate_spot_percent: Number(bnspotMaster),
                bn_master_rebate_futures_percent: Number(bnfuturesMaster),
                bn_self_rebate_spot_percent: Number(bnspotSelf),
                bn_self_rebate_futures_percent: Number(bnfuturesSelf),
                bybit_referral_spot_percent: Number(byspotMaster),
                bybit_referral_futures_percent: Number(byfuturesMaster),
                bybit_selferral_spot_percent: Number(byspotSelf),
                bybit_selferral_futures_percent: Number(byfuturesSelf),
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                setReload(e => !e)
            }).catch(error => {
                console.error(error);
            });
        }
    }

    const onSubmiteEmail = async () => {
        if (checkemail) {
            if (window.confirm("Are you sure you want to change email?") === true) {
                const objString = localStorage.getItem('token');
                if (objString != null) {
                    const obj = JSON.parse(objString);
                    let token = obj.value;

                    await axios.get('http://211.253.38.162:10220/api/v1/admin/member/email', {
                        params: {
                            uid: item.uid,
                            email: email
                        }
                    }, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }).then((response) => {
                        setReload(e => !e)
                    }).catch(error => {
                        console.error(error);
                    });
                }
            }
        }
    }

    const onSubmiteMobile = async () => {
        if (checkmobile) {
            if (window.confirm("Are you sure you want to change mobile?") === true) {
                const objString = localStorage.getItem('token');
                if (objString != null) {
                    const obj = JSON.parse(objString);
                    let token = obj.value;

                    await axios.get('http://211.253.38.162:10220/api/v1/admin/member/mobile', {
                        params: {
                            uid: item.uid,
                            country_code: phone,
                            mobile: mobile
                        }
                    }, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }).then((response) => {
                        setReload(e => !e)
                    }).catch(error => {
                        console.error(error);
                    });
                }
            }
        }
    }

    const onResetOTP = async () => {
        if (window.confirm("Are you sure you want to reset otp?") === true) {
            const objString = localStorage.getItem('token');
            if (objString != null) {
                const obj = JSON.parse(objString);
                let token = obj.value;

                await axios.get('http://211.253.38.162:10220/api/v1/admin/member/otp', {
                    params: {
                        uid: item.uid
                    }
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }).then((response) => {
                    setReload(e => !e)
                }).catch(error => {
                    console.error(error);
                });
            }
        }
    }

    const onResetPassword = async () => {
        if (window.confirm("Are you sure you want to reset password?") === true) {
            const objString = localStorage.getItem('token');
            if (objString != null) {
                const obj = JSON.parse(objString);
                let token = obj.value;

                await axios.get(`http://211.253.38.162:10220/api/v1/member/reset/password`, {
                    params: {
                        id: item.uid
                    },
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }).then((response) => {
                    setReload(e => !e)
                }).catch(error => {
                    console.error(error);
                });
            }
        }
    }

    const onConfirm = () => {
        if (window.confirm("Are you sure you want to reset data?") === true) {
            onChangeMasterData(selectStatusValue)
            setClickId(false)
        }
    }

    const handleEsc = (event) => {
        if (event.key === 'Escape') {
            setClickId(false);
        }
    };

    document.addEventListener('keydown', handleEsc);

    return (
        <div className='modal'>
            <div className={`modal-box`}>
                <div className={`modal-2 ${darkname}`}>
                    <div className='modal-1-tit-1'>
                        <div>Detail</div>
                        <div className={`modal-1-tit-box ${darkname}`}>RF-Rev ID : {detailData.uid}</div>
                        <div className={`modal-1-tit-box ${darkname}`}>Signup date : {signdate}</div>
                        <div className={`modal-1-tit-box ${darkname}`}>Last login : {lastsigndate}</div>
                    </div>
                    <div className='modal-2-1'>
                        <div className='modal-2-1-left'>
                            <div className='modal-2-1-left-box'>
                                {
                                    SelectType != 2
                                        ?
                                        <div className='modal-2-1-left-2'>
                                            <div className={`modal-2-1-right-box ${darkname}`}>
                                                <div>
                                                    <span style={{ paddingRight: "5px", borderRight: "1px solid #dbdbdb" }}>Email</span>
                                                    <input value={email} onChange={onChangeEmail} placeholder='Email' className='member-input' />
                                                </div>
                                                <div className='modify' onClick={() => onSubmiteEmail()}>modify</div>
                                            </div>
                                            <div className={`modal-2-1-right-box ${darkname}`}>
                                                <div><span style={{ paddingRight: "5px", borderRight: "1px solid #dbdbdb" }}>Mobile</span>
                                                    <input type="search" className='member-country-input' list="list" autoComplete="on" value={phone} onChange={onChangePhone} />
                                                    <datalist id="list">
                                                        {
                                                            Country.map(function (a, i) {
                                                                return (
                                                                    <option value={a.value}>{a.name} ({a.value})</option>
                                                                )
                                                            })
                                                        }
                                                    </datalist>
                                                    <input value={mobile} onChange={onChangeMobile} placeholder='Mobile' className='member-input' />
                                                </div>
                                                <div className='modify' onClick={() => onSubmiteMobile()}>modify</div>
                                            </div>
                                        </div>
                                        :
                                        ""
                                }
                                <div className='modal-2-1-left-box-1'>
                                    <div className={`modal-2-1-left-3 ${darkname}`} onClick={() => { onResetOTP() }}>Reset OTP</div>
                                    <div className={`modal-2-1-left-3 ${darkname}`} onClick={() => { onResetPassword() }}>Reset Password</div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='modal-2-1-right'>
                            <div className='modal-2-1-right-box'>
                                <div>Email <span style={{ paddingLeft: "5px" }}>{item.email}</span></div>
                                <div className='modify'>modify</div>
                            </div>
                            <div className='modal-2-1-right-box'>
                                <div>Mobile <span style={{ paddingLeft: "5px" }}>{item.mobile}</span></div>
                                <div className='modify'>modify</div>
                            </div>
                        </div> */}
                    </div>
                    <div className={`hr ${darkname}`}></div>
                    <div className='modal-2-2'>
                        <div className={`table-head ${darkname}`}>
                            <div style={{ width: "70%" }}></div>
                            <div>Total Trade</div>
                            <div>Level</div>
                            <div>Binance Master %</div>
                            <div>Binance Self %</div>
                            <div>OKX Master %</div>
                            <div>OKX Self %</div>
                            <div>Bybit Master %</div>
                            <div>Bybit Self %</div>
                        </div>
                        <div className='table-body'>
                            <div className={`table-body-tr ${darkname}`}>
                                <div className={`table-body-tr-1 td ${darkname}`} style={{ width: "70%" }}>Spot</div>
                                <div className='td'>{Number(totalTrade[0]).toFixed(6)}</div>
                                <div className='td'>
                                    <div className={`member-2-select border ${darkname}`} onClick={() => setSelectStatus(e => !e)}>
                                        <div className={`member-1-select-1 ${darkname}`}>{selectStatusValue}</div>
                                        {
                                            selectStatus
                                                ?
                                                <FontAwesomeIcon icon={faSortUp} />
                                                :
                                                <FontAwesomeIcon icon={faSortDown} />
                                        }
                                        {
                                            selectStatus
                                                ?
                                                <>
                                                    <div className='modal' onClick={() => setSelectStatus(true)}></div>
                                                    <div className={`member-2-select-box ${darkname}`}>
                                                        <div onClick={() => { setSelectStatusValue("AGENT"); setSelectStatus(true) }}>AGENT</div>
                                                        <div onClick={() => { setSelectStatusValue("MASTER"); setSelectStatus(true) }}>MASTER</div>
                                                    </div>
                                                </>
                                                :
                                                ""
                                        }
                                    </div>
                                </div>
                                <div className='td'>
                                    <input value={bnspotMaster} onChange={onChangeBnSpotMaster} placeholder={`${detailData.bn_master_rebate_spot_percent * 100}`} />
                                </div>
                                <div className='td'>
                                    <input value={bnspotSelf} onChange={onChangeBnSpotSelf} placeholder={`${detailData.bn_self_rebate_spot_percent * 100}`} />
                                </div>
                                <div className='td'>
                                    <input value={okxspotMaster} onChange={onChangeOkxSpotMaster} placeholder={`${detailData.okx_master_rebate_spot_percent * 100}`} />
                                </div>
                                <div className='td'>
                                    <input value={okxspotSelf} onChange={onChangeOkxSpotSelf} placeholder={`${detailData.okx_self_rebate_spot_percent * 100}`} />
                                </div>
                                <div className='td'>
                                    <input value={byspotMaster} onChange={onChangeBySpotMaster} placeholder={`${detailData.bybit_master_rebate_spot_percent * 100}`} />
                                </div>
                                <div className='td'>
                                    <input value={byspotSelf} onChange={onChangeBySpotSelf} placeholder={`${detailData.bybit_self_rebate_spot_percent * 100}`} />
                                </div>
                            </div>
                            <div className={`table-body-tr ${darkname}`}>
                                <div className={`table-body-tr-1 td ${darkname}`} style={{ width: "70%" }}>Futures</div>
                                <div className='td'>{Number(totalTrade[1]).toFixed(6)}</div>
                                <div className='td'>
                                    {/* <div className={`member-1-select border ${darkname}`} onClick={() => setSelectStatus1(e => !e)}>
                                        <div className={`member-1-select-1 ${darkname}`}>{selectStatusValue1}</div>
                                        {
                                            selectStatus1
                                                ?
                                                <FontAwesomeIcon icon={faSortUp} />
                                                :
                                                <FontAwesomeIcon icon={faSortDown} />
                                        }
                                        {
                                            selectStatus1
                                                ? <>
                                                    <div className='modal' onClick={() => setSelectStatus1(true)}></div>
                                                    <div className={`member-1-select-box ${darkname}`}>
                                                        <div onClick={() => { setSelectStatusValue1("AGENT"); setSelectStatus1(true) }}>AGENT</div>
                                                        <div onClick={() => { setSelectStatusValue1("MASTER"); setSelectStatus1(true) }}>MASTER</div>
                                                    </div>
                                                </>
                                                :
                                                ""
                                        }
                                    </div> */}
                                </div>
                                <div className='td'>
                                    <input value={bnfuturesMaster} onChange={onChangeBnFuturesMaster} placeholder={`${detailData.bn_master_rebate_futures_percent * 100}`} />
                                </div>
                                <div className='td'>
                                    <input value={bnfuturesSelf} onChange={onChangeBnFuturesSelf} placeholder={`${detailData.bn_self_rebate_futures_percent * 100}`} />
                                </div>
                                <div className='td'>
                                    <input value={okxfuturesMaster} onChange={onChangeOkxFuturesMaster} placeholder={`${detailData.okx_master_rebate_swap_percent * 100}`} />
                                </div>
                                <div className='td'>
                                    <input value={okxfuturesSelf} onChange={onChangeOkxFuturesSelf} placeholder={`${detailData.okx_self_rebate_swap_percent * 100}`} />
                                </div>
                                <div className='td'>
                                    <input value={byfuturesMaster} onChange={onChangeByFuturesMaster} placeholder={`${detailData.bybit_master_rebate_futures_percent * 100}`} />
                                </div>
                                <div className='td'>
                                    <input value={byfuturesSelf} onChange={onChangeByFuturesSelf} placeholder={`${detailData.bybit_self_rebate_futures_percent * 100}`} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='modal-2-3'>
                        <div className={`modal-2-3-btn ${darkname}`} onClick={() => { onConfirm() }}>Confirm</div>
                        <div className={`modal-2-3-btn ${darkname}`} onClick={() => setClickId(false)}>Close</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function ModalFunc({ item, setClickFune }) {
    const [type, setType] = useState(0)
    return (
        <div className='modal'>
            <div className='modal-box'>
                <div className='modal-1'>
                    <div className='modal-1-tit'>Func</div>
                    <div className='modal-1-1'>
                        <div className='modal-1-1-1'>
                            <div className='modal-1-1-1-tit'>ID</div>
                            <div className='modal-1-1-1-txt'>{item.id}</div>
                        </div>
                        <div className='modal-1-1-1'>
                            <div className='modal-1-1-1-tit'>Email</div>
                            <div className='modal-1-1-1-txt'>{item.email}</div>
                        </div>
                        <div className='modal-1-1-1'>
                            <div className='modal-1-1-1-tit'>Country</div>
                            <div className='modal-1-1-1-txt'>{item.country}</div>
                        </div>
                        <div className='modal-1-1-1'>
                            <div className='modal-1-1-1-tit'>Mobile</div>
                            <div className='modal-1-1-1-txt'>{item.mobile}</div>
                        </div>
                        <div className='modal-1-1-1'>
                            <div className='modal-1-1-1-tit'>Status</div>
                            <div className='modal-1-1-1-txt'>{item.status}</div>
                        </div>
                    </div>
                    <div className='modal-1-2-box'>
                        {
                            type === 0
                                ?
                                ""
                                :
                                type === 1
                                    ?
                                    <div className='modal-1-2'>
                                        <div className='modal-1-2-1'>Did you press Reset OTP</div>
                                        <div className='modal-1-2-2'>
                                            <div className='modal-1-2-2-btn'>Confirmed</div>
                                        </div>
                                    </div>
                                    :
                                    <div className='modal-1-2'>
                                        <div className='modal-1-2-1'>Did you press Reset Password</div>
                                        <div className='modal-1-2-2'>
                                            <div className='modal-1-2-2-btn'>Confirmed</div>
                                        </div>
                                    </div>
                        }
                    </div>
                    <div className='modal-1-3'>
                        <div className='modal-1-3-1' onClick={() => setType(1)}>Reset OTP</div>
                        <div className='modal-1-3-1' onClick={() => setType(2)}>Reset Password</div>
                        <div className='modal-1-3-1' onClick={() => setClickFune(false)}>Close</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Excel = (excelData, SelectType) => {
    const excelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const excelFileExtension = '.xlsx';
    const excelFileName = `Member`;

    const ws = XLSX.utils.aoa_to_sheet([
        ["RF-Rev ID", "Email", "Country", "Mobile", "Level", "Master", "Status", "Binance EXUID", "OKX EXUID", "Bybit EXUID"]
    ]);
    excelData.map((data) => {
        XLSX.utils.sheet_add_aoa(
            ws,
            [
                [
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.RFRevID },
                    SelectType != 2 ? { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.Email } : "",
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.Country },
                    SelectType != 2 ? { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.Mobile } : "",
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.Level },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.Master },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.Status },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.BnEXUID },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.OKXEXUID },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.ByEXUID }
                ]
            ],
            { origin: -1 }
        );
        ws['!cols'] = [
            { wpx: 60 },
            SelectType != 2 ? { wpx: 150 } : "",
            { wpx: 60 },
            SelectType != 2 ? { wpx: 100 } : "",
            { wpx: 60 },
            { wpx: 60 },
            { wpx: 60 },
            { wpx: 130 },
            { wpx: 130 },
            { wpx: 130 },
        ]
        return false;
    });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelButter = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const excelFile = new Blob([excelButter], { type: excelFileType });
    FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);
};


function changeTime(d) {
    d.setUTCHours(0);
    d.setUTCMinutes(0);
    d.setUTCSeconds(0);
    return d.getTime()
}

function padZero(value) {
    return value < 10 ? `0${value}` : value;
}

export default Member;
import '../css/main.css'
import { Link, useLocation, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faCircleXmark, faSortDown, faSortUp, faWindowMinimize, faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from 'react';
import Sidebar from '../../Nav/sidebar';
import NewPage from '../NewPage'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { checkLogin } from '../../store';
import { SyncLoader } from "react-spinners";
import { removeZeros, changeNum } from '../common/function'
import base64 from "base-64";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

let check_click = false

function Admin_Withdraw_History() {
    let login = useSelector((state) => { return state.login.value; });
    let dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            if (Date.now() > obj.expire) {
                localStorage.removeItem('token');
                dispatch(checkLogin(false));
                navigate('/')
            }
            else {
                dispatch(checkLogin(true));
            }
        }
        else {
            dispatch(checkLogin(false));
            navigate('/')
        }
    }, [])

    useEffect(() => {
        let objString = localStorage.getItem('token')
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;
            let payload = token.substring(
                token.indexOf(".") + 1,
                token.lastIndexOf(".")
            );
            let dec = base64.decode(payload);
            let sub = JSON.parse(dec).sub
            if (sub != "wodnjs7800@naver.com" && sub != "jb@hb.re.kr" && sub != "selina.ryu@coinrf.io") {
                navigate('/')
            }
        }
    }, [login])

    return (
        <div className='main'>
            <Sidebar page={24} />
            <Trading_History />
        </div>
    )
}


function Trading_History() {
    const [search, setSearch] = useState("")
    const [btn, setBtn] = useState(0)
    const [dateBtn, setDateBtn] = useState(0)
    const [select, setSelect] = useState(false)
    const [selectValue, setSelectValue] = useState("출금번호")
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());
    const [page, setPage] = useState(1)
    const [tradingData, setTradingData] = useState([])
    const [tradingList, setTradingList] = useState([])
    const [spinner, setSpinner] = useState(false)
    const [clickId, setClickId] = useState("")

    let dark = useSelector((state) => { return state.Darkmode.value });

    const darkname = dark ? 'dark' : "";

    const onChangeSearch = (e) => {
        setSearch(e.target.value)
        check_click = false
    }

    useEffect(() => {
        check_click = false
        setPage(1)
        TradingData()
    }, [btn])

    const TradingData = async () => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(`http://211.253.38.162:10220/api/v1/okx/trade-history?type=${btn === 0 ? "spot" : "swap"}`, {
                params: {
                    start: changeTime(selectedDate),
                    end: changeTime(selectedDate1),
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let tmp = response.data.data
                if (tmp) {
                    setTradingData(tmp)
                }
                setTimeout(() => {
                    setSpinner(false)
                }, 500)
            }).catch(error => {
                console.error(error);
            });
        }
    }

    useEffect(() => {
        onSpinner()
        setPage(1)
    }, [selectedDate, selectedDate1, search])

    const onSpinner = () => {
        setSpinner(true)
        setTimeout(() => {
            setSpinner(false)
        }, 500)
    }

    const excelData = () => {
        let arr = []
        if (tradingList) {
            for (let i = 0; i < tradingList.length; i++) {
                let t = tradingList[i]
                let date = t.fillTime.split('T')
                arr.push({
                    "Time": date ? date[0] : t.fillTime,
                    "RFRevID": t.uid,
                    "EXUID": t.exuid,
                    "Asset": t.asset,
                    "Side": t.POSITION,
                    "Volume": Number(t.volume).toFixed(5),
                    "Price": t.price,
                    "Quantity": t.quantity,
                    "Fee": btn === 0 ? (Math.abs(t.fee) * (t.POSITION === "BUY" ? Number(t.price) : 1)).toFixed(8) : Math.abs(t.fee).toFixed(8),
                    "Commission": btn === 0 ? (Math.abs(t.fee) * (t.POSITION === "BUY" ? Number(t.price) : 1) * 0.6).toFixed(8) : Math.abs(t.fee * 0.6).toFixed(8)
                })
            }
        }

        // Excel(btn, arr, "OKX")
    }

    let slice_data = tradingList.slice((page - 1) * 15, (page) * 15)

    return (
        <div className={`main-box ${darkname}`} style={{ minWidth: "1500px" }}>
            <div className={`withdraw-1 ${darkname}`}>
                <div style={{ width: "120px", fontWeight: "bold" }}>레퍼럴 출금내역</div>
                <div className='member-1'>
                    <div className={`member-2-search ${darkname}`}>
                        <div className={`member-1-select ${darkname}`} onClick={() => setSelect(e => !e)}>
                            <div className={`member-1-select-1 ${darkname}`}>{selectValue}</div>
                            {
                                select
                                    ?
                                    <FontAwesomeIcon icon={faSortUp} />
                                    :
                                    <FontAwesomeIcon icon={faSortDown} />
                            }
                            {
                                select
                                    ? <>
                                        <div className='modal' onClick={() => setSelect(true)}></div>
                                        <div className={`member-1-select-box ${darkname}`}>
                                            <div onClick={() => { setSelectValue("출금번호"); setSelect(true) }}>출금번호</div>
                                            <div onClick={() => { setSelectValue("레퍼럴"); setSelect(true) }}>레퍼럴</div>
                                        </div>
                                    </>
                                    :
                                    ""
                            }
                        </div>
                        <FontAwesomeIcon icon={faWindowMinimize} className='line' />
                        <div className={`member-2-search-1 ${darkname}`}>
                            <input value={search} onChange={onChangeSearch} placeholder='Search' />
                            <FontAwesomeIcon icon={faMagnifyingGlass} className='member-1-search-ico-3' />
                            {
                                search && search.length > 0
                                    ?
                                    <FontAwesomeIcon icon={faCircleXmark} className='member-1-search-ico-2' onClick={() => { setSearch(""); check_click = false }} />
                                    :
                                    ""
                            }
                        </div>
                    </div>
                </div>
                <div className={`modal-3-1-left ${darkname}`}>
                    <div className={dateBtn === 0 ? "modal-3-1-left-1 on flex" : "modal-3-1-left-1 flex"} onClick={() => setDateBtn(0)}>
                        <DatePicker
                            dateFormat='yyyy-MM-dd' // 날짜 형태
                            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                            minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                            maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                            selected={selectedDate}
                            onChange={(date) => { setSelectedDate(date); setSelectedDate1(date) }}
                        />
                    </div>
                    <div className={dateBtn === 1 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(1);
                        setSelectedDate(new Date((new Date()).getTime())); setSelectedDate1(new Date())
                    }}>Today</div>
                    <div className={dateBtn === 2 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(2);
                        setSelectedDate(new Date(new Date().getTime() - 604800000)); setSelectedDate1(new Date())
                    }}>7 Days</div>
                    <div className={dateBtn === 3 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(3);
                        setSelectedDate(new Date(new Date().getTime() - 2592000000)); setSelectedDate1(new Date())
                    }}>30 Days</div>
                    <div className={dateBtn === 4 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(4);
                        setSelectedDate(new Date(new Date().getTime() - 7776000000)); setSelectedDate1(new Date())
                    }}>90 Days</div>
                    <div className={dateBtn === 5 ? "modal-3-1-left-1 on flex" : "modal-3-1-left-1 flex"} onClick={() => setDateBtn(5)}>
                        <DatePicker
                            dateFormat='yyyy-MM-dd' // 날짜 형태
                            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                            minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                            maxDate={selectedDate1} // maxDate 이후 날짜 선택 불가
                            selected={selectedDate}
                            onChange={(date) => setSelectedDate(date)}
                        />
                        <div>~</div>
                        <DatePicker
                            dateFormat='yyyy-MM-dd' // 날짜 형태
                            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                            minDate={selectedDate} // minDate 이전 날짜 선택 불가
                            maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                            selected={selectedDate1}
                            onChange={(date) => setSelectedDate1(date)}
                        />
                    </div>
                </div>
            </div>
            <div className='tradingType-1'>
                <div className={`tradingType-1-2 ${darkname}`} onClick={() => excelData()}>Excel download</div>
            </div>
            <div className='tradingType-2'>
                <div className={`table-head ${darkname}`}>
                    <div>출금번호</div>
                    <div>레퍼럴</div>
                    <div>수령인</div>
                    <div>수량</div>
                    <div>거래소</div>
                    <div>일시</div>
                </div>
                <div className='table-body' style={{ minHeight: "600px" }}>
                    {
                        spinner
                            ?
                            <div className='spinner-modal'>
                                <SyncLoader color="#F5AC1D" />
                            </div>
                            :
                            slice_data && slice_data.length === 0
                                ?
                                <div className='data-nofound'>
                                    <img src='/img/img_nofound.png'></img>
                                    <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                    <p>NO RECORDS FOUND.</p>
                                </div>
                                :
                                <>
                                    {
                                        slice_data.map(function (a, i) {
                                            return (
                                                <Item key={i} item={a} darkname={darkname} btn={btn} setClickId={setClickId} setSearch={setSearch} />
                                            )
                                        })
                                    }
                                    <NewPage initialData={tradingList} page={page} setPage={setPage} maxFirstPage={15} />
                                </>
                    }
                </div>
            </div>
        </div>
    )
}

function Item({ item, darkname, btn, setClickId, setSearch }) {
    let arr = item.fillTime.split('T')
    let arr1 = arr[1].split('.000Z')

    return (
        <div className={`table-body-tr ${darkname}`}>
            <div className='td'>{arr && arr1 ? `${arr[0]} ${arr1[0]}` : item.fillTime}</div>
            <div className='onclick td' onClick={() => { setClickId(`${item.uid}`); check_click = true; setSearch(`${item.uid}`) }}>{item.uid}</div>
            <div className='td'>{item.exuid}</div>
            <div className='td'>{item.asset}</div>
            <div className='td'>{item.POSITION}</div>
            <div className='right td'>{changeNum(Number(item.volume).toFixed(5))}</div>
            <div className='right td'>{changeNum(removeZeros(item.price))}</div>
        </div>
    )
}

function changeTime(d) {
    d.setUTCHours(0);
    d.setUTCMinutes(0);
    d.setUTCSeconds(0);
    return d.getTime()
}

const Excel = (btn, excelData, exchange) => {
    const excelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const excelFileExtension = '.xlsx';
    const excelFileName = `Trading_History_${exchange}_${btn === 0 ? "Spot" : "Futures"}`;

    const ws = XLSX.utils.aoa_to_sheet([
        ["Time", "RF-Rev ID", "EXUID", btn === 0 ? "Asset" : "Symbol", "Side", "Volume", "Price", "Quantity", "Fee", "Commission"]
    ]);
    excelData.map((data) => {
        XLSX.utils.sheet_add_aoa(
            ws,
            [
                [
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.Time },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.RFRevID },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.EXUID },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.Asset },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.Side },
                    { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.Volume },
                    { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.Price },
                    { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.Quantity },
                    { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.Fee },
                    { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.Commission }
                ]
            ],
            { origin: -1 }
        );
        ws['!cols'] = [
            { wpx: 80 },
            { wpx: 60 },
            { wpx: 130 },
            { wpx: 120 },
            { wpx: 60 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
        ]
        return false;
    });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelButter = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const excelFile = new Blob([excelButter], { type: excelFileType });
    FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);
};

function padZero(value) {
    return value < 10 ? `0${value}` : value;
}

export default Admin_Withdraw_History;
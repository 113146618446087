import '../css/main.css'
import { Link, useLocation, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faCircleXmark, faSortDown, faSortUp, faWindowMinimize, faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from 'react';
import Sidebar from '../../Nav/sidebar';
import NewPage from '../NewPage'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { checkLogin } from '../../store';
import { SyncLoader } from "react-spinners";
import { removeZeros, changeNum } from '../common/function'
import base64 from "base-64";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

function Admin_Trading_History() {
    let login = useSelector((state) => { return state.login.value; });
    let dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            if (Date.now() > obj.expire) {
                localStorage.removeItem('token');
                dispatch(checkLogin(false));
                navigate('/')
            }
            else {
                dispatch(checkLogin(true));
            }
        }
        else {
            dispatch(checkLogin(false));
            navigate('/')
        }
    }, [])

    useEffect(() => {
        let objString = localStorage.getItem('token')
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;
            let payload = token.substring(
                token.indexOf(".") + 1,
                token.lastIndexOf(".")
            );
            let dec = base64.decode(payload);
            let sub = JSON.parse(dec).sub
            if (sub != "wodnjs7800@naver.com" && sub != "jb@hb.re.kr" && sub != "selina.ryu@coinrf.io") {
                navigate('/')
            }
        }
    }, [login])

    return (
        <div className='main'>
            <Sidebar page={22} />
            <Trading_History />
        </div>
    )
}


function Trading_History() {
    const [search, setSearch] = useState("")
    const [btn, setBtn] = useState(0)
    const [dateBtn, setDateBtn] = useState(0)
    const [select, setSelect] = useState(false)
    const [selectValue, setSelectValue] = useState("거래 ID")
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());
    const [page, setPage] = useState(1)
    const [tradingData, setTradingData] = useState([])
    const [tradingSort, setTradingSort] = useState([])
    const [tradingList, setTradingList] = useState([])
    const [spinner, setSpinner] = useState(false)

    let dark = useSelector((state) => { return state.Darkmode.value });

    const darkname = dark ? 'dark' : "";

    const onChangeSearch = (e) => {
        setSearch(e.target.value)
    }

    useEffect(() => {
        TradingData()
    }, [])

    const TradingData = async () => {
        await axios.get('https://dev.broker-api.goex.kr/api/v1/broker/admin/get/tradelist', {
            withCredentials: true,
        }).then((response) => {
            let tmp = response.data
            if (tmp) {
                setTradingData(tmp)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const groupDataById = (data) => {
        const grouped = data.reduce((acc, current) => {
            if (!acc[current.trade_id]) {
                acc[current.trade_id] = [];
            }
            acc[current.trade_id].push(current);
            return acc;
        }, {});

        return Object.values(grouped);
    };

    useEffect(() => {
        let list = []
        if (tradingData) {
            const groupedData = groupDataById(tradingData);
            for (let i = 0; i < groupedData.length; i++) {
                let tmp = groupedData[i]
                let arr = []
                for (let j = 0; j < tmp.length; j++) {
                    if (j === 0) {
                        arr.ex = tmp[j].ex
                        arr.fee = tmp[j].fee
                        arr.fill_time = tmp[j].fill_time
                        arr.trade_id = tmp[j].trade_id
                        arr.trader = tmp[j].trader
                        arr.volume = tmp[j].volume
                        arr.total_income = tmp[j].total_income
                    }
                    if (tmp[j].broker_grade == '1') arr.b1 = tmp[j].income ?? '0'
                    else if (tmp[j].broker_grade == '2') arr.b2 = tmp[j].income ?? '0'
                    else if (tmp[j].broker_grade == '3') arr.b3 = tmp[j].income ?? '0'
                }
                list.push(arr)
            }
            setTradingSort(list)
        }
    }, [tradingData])

    useEffect(() => {
        let arr = []
        let filter = []
        if (tradingSort && tradingSort.length > 0) {
            for (let i = 0; i < tradingSort.length; i++) {
                if (Math.floor(changeTime(selectedDate) / 1000) * 1000 <= new Date(tradingSort[i].fill_time).getTime() && new Date(tradingSort[i].fill_time).getTime() <= Math.floor(changeTime(selectedDate1) / 1000) * 1000 + 86399999) {
                    arr.push(tradingSort[i])
                }
            }

            if (selectValue == "거래 ID" && search.length > 0) {
                filter = arr.filter((p) => {
                    return p.trade_id.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
            }
            else {
                filter = arr
            }

            if (btn === 0) {
                filter = filter.filter((p) => {
                    return p.ex == "BINANCE"
                })
            }
            else {
                filter = filter.filter((p) => {
                    return p.ex == "OKX"
                })
            }

            setTradingList(filter)
        }
    }, [selectedDate, selectedDate1, tradingData, search, btn])

    useEffect(() => {
        onSpinner()
        setPage(1)
    }, [selectedDate, selectedDate1, search, btn])

    const onSpinner = () => {
        setSpinner(true)
        setTimeout(() => {
            setSpinner(false)
        }, 500)
    }

    const excelData = () => {
        let arr = []
        if (tradingList) {
            for (let i = 0; i < tradingList.length; i++) {
                let t = tradingList[i]
                let date = t.fill_time.split('T')
                let date1 = date[1].split('.000Z')
                arr.push({
                    "거래ID": t.trade_id,
                    "트레이더": t.trader,
                    "레퍼럴1": t.b1,
                    "레퍼럴2": t.b2,
                    "레퍼럴3": t.b3,
                    "총레퍼럴": t.total_income,
                    "거래량": t.volume,
                    "수수료": t.fee,
                    "거래일시": `${date[0]} ${date1[0]}`,
                })
            }
        }

        Excel(btn, arr)
    }

    let slice_data = tradingList.slice((page - 1) * 15, (page) * 15)

    return (
        <div className={`main-box ${darkname}`} style={{ minWidth: "1500px" }}>
            <div className={`withdraw-1 ${darkname}`}>
                <div style={{ width: "120px", fontWeight: "bold" }}>레퍼럴 거래내역</div>
                <div className='member-1'>
                    <div className={`member-2-search ${darkname}`}>
                        <div className={`member-1-select ${darkname}`} onClick={() => setSelect(e => !e)}>
                            <div className={`member-1-select-1 ${darkname}`}>{selectValue}</div>
                            {
                                select
                                    ?
                                    <FontAwesomeIcon icon={faSortUp} />
                                    :
                                    <FontAwesomeIcon icon={faSortDown} />
                            }
                            {
                                select
                                    ? <>
                                        <div className='modal' onClick={() => setSelect(true)}></div>
                                        <div className={`member-1-select-box ${darkname}`}>
                                            <div onClick={() => { setSelectValue("거래 ID"); setSelect(true) }}>거래 ID</div>
                                        </div>
                                    </>
                                    :
                                    ""
                            }
                        </div>
                        <FontAwesomeIcon icon={faWindowMinimize} className='line' />
                        <div className={`member-2-search-1 ${darkname}`}>
                            <input value={search} onChange={onChangeSearch} placeholder='Search' />
                            <FontAwesomeIcon icon={faMagnifyingGlass} className='member-1-search-ico-3' />
                            {
                                search && search.length > 0
                                    ?
                                    <FontAwesomeIcon icon={faCircleXmark} className='member-1-search-ico-2' onClick={() => { setSearch("") }} />
                                    :
                                    ""
                            }
                        </div>
                    </div>
                </div>
                <div className={`modal-3-1-left ${darkname}`}>
                    <div className={dateBtn === 0 ? "modal-3-1-left-1 on flex" : "modal-3-1-left-1 flex"} onClick={() => setDateBtn(0)}>
                        <DatePicker
                            dateFormat='yyyy-MM-dd' // 날짜 형태
                            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                            minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                            maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                            selected={selectedDate}
                            onChange={(date) => { setSelectedDate(date); setSelectedDate1(date) }}
                        />
                    </div>
                    <div className={dateBtn === 1 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(1);
                        setSelectedDate(new Date((new Date()).getTime())); setSelectedDate1(new Date())
                    }}>Today</div>
                    <div className={dateBtn === 2 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(2);
                        setSelectedDate(new Date(new Date().getTime() - 604800000)); setSelectedDate1(new Date())
                    }}>7 Days</div>
                    <div className={dateBtn === 3 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(3);
                        setSelectedDate(new Date(new Date().getTime() - 2592000000)); setSelectedDate1(new Date())
                    }}>30 Days</div>
                    <div className={dateBtn === 4 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(4);
                        setSelectedDate(new Date(new Date().getTime() - 7776000000)); setSelectedDate1(new Date())
                    }}>90 Days</div>
                    <div className={dateBtn === 5 ? "modal-3-1-left-1 on flex" : "modal-3-1-left-1 flex"} onClick={() => setDateBtn(5)}>
                        <DatePicker
                            dateFormat='yyyy-MM-dd' // 날짜 형태
                            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                            minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                            maxDate={selectedDate1} // maxDate 이후 날짜 선택 불가
                            selected={selectedDate}
                            onChange={(date) => setSelectedDate(date)}
                        />
                        <div>~</div>
                        <DatePicker
                            dateFormat='yyyy-MM-dd' // 날짜 형태
                            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                            minDate={selectedDate} // minDate 이전 날짜 선택 불가
                            maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                            selected={selectedDate1}
                            onChange={(date) => setSelectedDate1(date)}
                        />
                    </div>
                </div>
            </div>
            <div className='tradingType-1'>
                <div className={`withdraw-2 ${darkname}`}>
                    <div className={btn === 0 ? "on" : ""} onClick={() => { setSpinner(btn === 1 ? true : false); setBtn(0) }}>Binance</div>
                    <div className={btn === 1 ? "on" : ""} onClick={() => { setSpinner(btn === 0 ? true : false); setBtn(1) }}>OKX</div>
                </div>
                <div className={`tradingType-1-2 ${darkname}`} onClick={() => excelData()}>Excel download</div>
            </div>
            <div className='tradingType-2'>
                <div className={`table-head ${darkname}`}>
                    <div style={{width:'50%'}}>거래 ID</div>
                    <div style={{width:'50%'}}>트레이더</div>
                    <div>레퍼럴1</div>
                    <div>레퍼럴2</div>
                    <div>레퍼럴3</div>
                    <div>총 레퍼럴</div>
                    <div>거래량</div>
                    <div>수수료</div>
                    <div>거래일시</div>
                </div>
                <div className='table-body' style={{ minHeight: "600px" }}>
                    {
                        spinner
                            ?
                            <div className='spinner-modal'>
                                <SyncLoader color="#F5AC1D" />
                            </div>
                            :
                            slice_data && slice_data.length === 0
                                ?
                                <div className='data-nofound'>
                                    <img src='/img/img_nofound.png'></img>
                                    <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                    <p>NO RECORDS FOUND.</p>
                                </div>
                                :
                                <>
                                    {
                                        slice_data.map(function (a, i) {
                                            return (
                                                <Item key={i} item={a} darkname={darkname} />
                                            )
                                        })
                                    }
                                    <NewPage initialData={tradingList} page={page} setPage={setPage} maxFirstPage={15} />
                                </>
                    }
                </div>
            </div>
        </div>
    )
}

function Item({ item, darkname }) {
    let arr = item.fill_time.split('T')
    let arr1 = arr[1].split('.000Z')

    return (
        <div className={`table-body-tr ${darkname}`}>
            <div className='td' style={{width:'50%'}}>{item.trade_id}</div>
            <div className='td' style={{width:'50%'}}>{item.trader}</div>
            <div className='td right'>{item.b1}</div>
            <div className='td right'>{item.b2}</div>
            <div className='td right'>{item.b3}</div>
            <div className='td right'>{item.total_income}</div>
            <div className='td right'>{item.volume}</div>
            <div className='td right'>{item.fee}</div>
            <div className='td'>{`${arr[0]} ${arr1[0]}`}</div>
        </div>
    )
}

function changeTime(d) {
    d.setUTCHours(0);
    d.setUTCMinutes(0);
    d.setUTCSeconds(0);
    return d.getTime()
}

const Excel = (btn, excelData) => {
    const excelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const excelFileExtension = '.xlsx';
    const excelFileName = `Referrer_Trading_History_${btn === 0 ? "Binance" : "OKX"}`;

    const ws = XLSX.utils.aoa_to_sheet([
        ["거래ID", "트레이더", "레퍼럴1","레퍼럴2","레퍼럴3","총레퍼럴", "거래량", "수수료", "거래일시"]
    ]);
    excelData.map((data) => {
        XLSX.utils.sheet_add_aoa(
            ws,
            [
                [
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.거래ID },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.트레이더 },
                    { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.레퍼럴1 },
                    { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.레퍼럴2 },
                    { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.레퍼럴3 },
                    { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.총레퍼럴 },
                    { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.거래량 },
                    { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.수수료 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.거래일시 },
                ]
            ],
            { origin: -1 }
        );
        ws['!cols'] = [
            { wpx: 120 },
            { wpx: 120 },
            { wpx: 120 },
            { wpx: 120 },
            { wpx: 120 },
            { wpx: 120 },
            { wpx: 120 },
        ]
        return false;
    });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelButter = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const excelFile = new Blob([excelButter], { type: excelFileType });
    FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);
};

function padZero(value) {
    return value < 10 ? `0${value}` : value;
}

export default Admin_Trading_History;